<template>
  <div :style="{backgroundImage:`url(${images.background})`}" style="background-position: left bottom; background-repeat: repeat-x">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true">
    </loading>
    <div id="router" class="pt-10" :style="[{'height' : (haveToken) ? 'calc(100vh)' : (innerWidth > 1125) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]">
      <v-row justify-content="center" :style="[{'margin' : (innerWidth > 959) ? '1% 8%' : '0% 0%'}]">
        <v-col class="col-12 col-md-6 my-auto" align="center">
          <v-img :src="images.privacyPage" width=600 alt="action_04"></v-img>
        </v-col>

        <v-col class="col-12 col-md-6 px-0" align="center">
          <h4 class="styleH4">{{$t('__privacyConsentWithdraw')}}</h4>
          <div id="box">
            <v-row class="my-1 px-6 text-left weight600" style="height:300px; overflow-y:scroll;">
              <div v-html="content" style="width:100%"></div>
            </v-row>
            <div class="my-6 mx-3" style="min-height:2px; background:#52575A">
            </div>

            <h4 class="styleT2 mx-3 mb-3 text-left">{{$t('__applicantInformation')}}</h4>

            <v-text-field class="text_field px-3" dense readonly background-color="#F4F8F9" outlined
              :label="$t('__memberName')" v-model="datasets.memberName">
            </v-text-field>
            <v-text-field class="text_field px-3" dense readonly background-color="#F4F8F9" outlined
              :label="$t('__memberEmail')" v-model="datasets.email">
            </v-text-field>

            <v-row class="ma-0 mt-2">
              <v-col class="pa-0 col-1">
                <v-row class="ma-0 pa-0" justify="end">
                  <v-checkbox color="#4880C8" class="ma-0 pa-0" style="height:20px" v-model="datasets.withdrawItem" value="1"></v-checkbox>
                </v-row>
              </v-col>
              <v-col class="pl-1 pa-0 col-11 text-left">
                <span class="text_field">{{$t('__privacyWithdrawItem1')}}</span>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="pa-0 col-1">
                <v-row class="ma-0 pa-0" justify="end">
                  <v-checkbox color="#4880C8" class="ma-0 pa-0" style="height:20px" v-model="datasets.withdrawItem" value="2"></v-checkbox>
                </v-row>
              </v-col>
              <v-col class="pl-1 pa-0 col-11 text-left">
                <span class="text_field">{{$t('__privacyWithdrawItem2')}}</span>
              </v-col>
            </v-row>
            <v-btn class="styleBtn1 border" outlined style="max-width:50%; color:#FFF; background:#4880C8" @click="confirmBtn()">
              <h5 class="center">{{$t('__confirmWithdrawal')}}</h5>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div v-if="haveToken" style="position: absolute; top:10px; left:10px;"> 
        <div class="ma-1" style="background:#222; border-radius: 30px; height:36px; width:36px; opacity: 0.7; z-index:5"
        @click="redirectPath('/privacyPage')">
          <v-icon class="ma-1" size="28px" color="#FFF">mdi-menu-left</v-icon>
        </div>
        <v-img :src="datasets.mainImageImage" width=500 alt="action_04"></v-img>
      </div>
    </div>
    <ShowMsg ref="ShowMsg" :onSave="onShowMsg"/>
    <ShowConfirm ref="ShowConfirm" :onSave="onShowConfirm"/>
  </div>
</template>

<script>
import ShowMsg from '@/dialogs/ShowMsg'
import ShowConfirm from '@/dialogs/ShowConfirm'
import i18n from '@/plugins/i18n'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.min.css'
export default {
  name: 'PrivacyApply',
  components: {ShowMsg,ShowConfirm,Loading},
  mounted(){
    this.language = i18n.locale
    this.datasets.memberId = sessionStorage.getItem('myCalId')
    this.datasets.memberName = sessionStorage.getItem('myCalName')
    this.datasets.email = sessionStorage.getItem('myCalEmail')
    this.getDataset()
  },
  data:() => ({
    language:'',
    privacyDatasets:[],
    datasets:{},
    haveToken: false,
    innerWidth: 0,
    images:{
      background: require('@/assets/image/background-2000.png'),
      privacyPage: require('@/assets/image/privacyPage.png'),
    },
    isLoading: false,
    content:'',
    requiredField:['memberId','memberName','email','withdrawItem'],
  }),
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
    this.$root.$on('language',(value)=>{
      this.language = value
    })
  },
  watch:{
    "language":function(){
      this.changeLanguageSel()
    },
  },
  methods:{
    calculateWindows(){
      if(this.$cookie.get('token') || this.$cookie.get('token') == ''){
        this.haveToken = true
      }
      this.innerWidth = window.innerWidth
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    changeLanguageSel(){
      if(this.language=='tw'){
        this.content = this.privacyDatasets.twContent
      }else if(this.language=='cn'){
        this.content = this.privacyDatasets.cnContent
      }else if(this.language=='en'){
        this.content = this.privacyDatasets.enContent
      }
    },
    confirmBtn(){
      if(this.datasets.withdrawItem == '1'){
        this.$refs.ShowConfirm.open(5,'','',true)
      }else if(this.datasets.withdrawItem == '2'){
        this.$refs.ShowConfirm.open(6,'','',true)
      }else{
        this.$refs.ShowMsg.open(11)
      }
    },
    getDataset(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/PrivacyWithdrawal',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.privacyDatasets = response.data.data[0]
      }).catch(()=>{
      }).finally(()=>{
        self.changeLanguageSel()
      })
    },
    newDataset() {
      if(!this.$Global.checkForm(this.datasets,this.requiredField)){
        this.$refs.ShowMsg.open(1)
        return
      }
      this.isLoading = true
      var allData = this.$Global.copy(this.datasets)
      allData = this.$Global.jsonConcat(allData,{'languageSystem': this.language})
      allData = this.$Global.jsonConcat(allData,{'status': '2'})
      var self = this
      var request = {
        method: 'POST',
        url: '/privacyWithdrawal',
        data: this.Qs.stringify(allData)
      }
      this.$http(request).then(function(response) {
        if(self.datasets.withdrawItem == '2'){
          self.$refs.ShowMsg.open(12,'','',true,true,'2')
        }else{
          self.$refs.ShowMsg.open(12,'','',true,true)
        }
        self.isLoading = false
      }).catch(()=>{
        self.isLoading = false
      })
    },
    onShowMsg(info){
      if(info == '2'){
        sessionStorage.removeItem('myCalToken')
        sessionStorage.removeItem('myCalName')
        sessionStorage.removeItem('myCalId')
        sessionStorage.removeItem('myCalEmail')
        sessionStorage.removeItem('myCalExpiration')
        sessionStorage.removeItem('myCalMemberStatus')
        sessionStorage.removeItem('totalDietDays')
        // this.$cookie.delete('myCookie');
        // this.$cookie.delete('myCouponCookie');
        this.redirectPath('/privacyPage')
      }else{
        this.redirectPath('/privacyPage')
      }
    },
    onShowConfirm(){
      this.newDataset()
    },
  }
}
</script>

