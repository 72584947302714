<template>
  <div>
    <div v-show="!initDone">
      <v-row class="ma-0" style="justify-content:center; height:100%; background:#FBFBFB" :style="[{'height' : (innerWidth > 1125) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]">
        <div class="pt-8" style="width:90%; min-height:400px; max-width:600px;">
          <v-img :src="images.vegetables" eager></v-img>
        </div>
      </v-row>
    </div>
    <div v-show="initDone" id="router" class="pt-10" :style="[{'height' : (innerWidth > 1125) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]">
      <v-row class="ma-0 pa-0" style="justify-content:center">
        <v-col class="ma-0 py-0 px-5 mb-5 col-11 col-md-6">
          <v-carousel show-arrows-on-hover cycle interval="10000" height="auto">
            <v-carousel-item eager v-for="(item,i) in imageCarouselList" :key="i">
              <v-img eager :src="item"/>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
      <div v-if="datasets.length != 0" style="margin: 2% 0%; padding: 2% 0%; justify-content:center; background:#D9EBF5">
        <v-row class="ma-0 px-0 py-2" style="width:100%; justify-content:center">
          <v-row class="ma-0 py-0 px-5 col-11 col-md-6">
            <div v-if="imageItemList2[0]" style="width:100%">
              <v-img :src="imageItemList2[0]" eager></v-img>
            </div>
          </v-row>
        </v-row>
        <v-row class="mx-0 my-2 pa-0" style="width:100%; justify-content:center">
          <div class="ma-0 pt-5 pb-0 px-5 col-11 col-md-6" style="justify-content:center">
            <h1 class="styleH4">{{datasets[0].content}}</h1>
            <!-- <h1 class="styleT3 my-1">{{datasets[1].content}}</h1> -->
          </div>
        </v-row>
        <v-row class="mx-0 pa-0 mt-6" style="width:100%; justify-content:center">
          <!-- <v-row class="my-0 pa-0 col-11 col-md-6 mx-n10" style="justify-content:space-between"> //對應開啟-1 -->
          <v-row class="my-0 pa-0 col-11 col-md-6 mx-n10" style="justify-content:space-around">
            <v-col class="col-12 col-md-4 pa-5">
              <v-row class="ma-0 pa-0" style="justify-content:center">
                <div v-if="imageItemList2[1]" style="width:100%">
                  <v-img :src="imageItemList2[1]" eager></v-img>
                </div>
              </v-row>
              <h3 class="styleH4">{{datasets[5].content}}</h3>
              <h3 class="styleT3" :style="{'font-style' : (language=='en') ? 'italic' : ''}">{{datasets[6].content}}</h3>
              <h4 class="styleT4 text-center">{{datasets[7].content}}</h4>
            </v-col>
            <v-col class="col-12 col-md-4 pa-5">
              <v-row class="ma-0 pa-0" style="justify-content:center">
                <div v-if="imageItemList2[2]" style="width:100%">
                  <v-img :src="imageItemList2[2]" eager></v-img>
                </div>
              </v-row>
              <h3 class="styleH4">{{datasets[9].content}}</h3>
              <h3 class="styleT3" :style="{'font-style' : (language=='en') ? 'italic' : ''}">{{datasets[10].content}}</h3>
              <h4 class="styleT4 text-center">{{datasets[11].content}}</h4>
            </v-col>
          </v-row>
        </v-row>
        <v-row class="mx-0 pa-0 mt-6" style="width:100%; justify-content:center">
          <v-row class="my-0 pa-0 col-11 col-md-6 mx-n10" style="justify-content:space-between">
            <v-col class="col-12 col-md-3 pa-5">
              <v-row class="ma-0 pa-0" style="justify-content:center">
                <div v-if="imageItemList2[3]" style="width:100%">
                  <v-img :src="imageItemList2[3]" eager></v-img>
                </div>
              </v-row>
              <h3 class="styleH4">{{datasets[13].content}}</h3>
              <h3 class="styleT3" :style="{'font-style' : (language=='en') ? 'italic' : ''}">{{datasets[14].content}}</h3>
              <h4 class="styleT4 text-center">{{datasets[15].content}}</h4>
            </v-col>
            <v-col class="col-12 col-md-3 pa-5">
              <v-row class="ma-0 pa-0" style="justify-content:center">
                <div v-if="imageItemList2[4]" style="width:100%">
                  <v-img :src="imageItemList2[4]" eager></v-img>
                </div>
              </v-row>
              <h3 class="styleH4">{{datasets[17].content}}</h3>
              <h3 class="styleT3" :style="{'font-style' : (language=='en') ? 'italic' : ''}">{{datasets[18].content}}</h3>
              <h4 class="styleT4 text-center">{{datasets[19].content}}</h4>
            </v-col>
            <v-col class="col-12 col-md-3 pa-5">
              <v-row class="ma-0 pa-0" style="justify-content:center">
                <div v-if="imageItemList2[5]" style="width:100%">
                  <v-img :src="imageItemList2[5]" eager></v-img>
                </div>
              </v-row>
              <h3 class="styleH4">{{datasets[21].content}}</h3>
              <h3 class="styleT3" :style="{'font-style' : (language=='en') ? 'italic' : ''}">{{datasets[22].content}}</h3>
              <h4 class="styleT4 text-center">{{datasets[23].content}}</h4>
            </v-col>
            <v-col class="col-12 col-md-3 pa-5">
              <v-row class="ma-0 pa-0" style="justify-content:center">
                <div v-if="imageItemList2[6]" style="width:100%">
                  <v-img :src="imageItemList2[6]" eager></v-img>
                </div>
              </v-row>
              <h3 class="styleH4">{{datasets[25].content}}</h3>
              <h3 class="styleT3" :style="{'font-style' : (language=='en') ? 'italic' : ''}">{{datasets[26].content}}</h3>
              <h4 class="styleT4 text-center">{{datasets[27].content}}</h4>
            </v-col>
          </v-row>
        </v-row>
        <v-row class="mx-0 pa-0 mt-6" style="width:100%; justify-content:center">
          <h1 class="styleH4">{{datasets[28].content}}</h1>
        </v-row>
        
        <v-row class="mx-0 pa-0 mt-6" style="width:100%; justify-content:center">
          <v-row class="my-0 pa-0 col-11 col-md-6 mx-n10" style="justify-content:space-around">
            <v-col class="col-12 col-sm-10 col-md-8 pa-5">
              <v-row class="ma-0 pa-0" style="justify-content:center">
                <div v-if="imageItemList2[7]" style="width:100%">
                  <v-img :src="imageItemList2[7]" eager></v-img>
                </div>
              </v-row>
              <!-- <v-row class="ma-0 pa-0" style="justify-content: center; display: flex; flex-wrap: nowrap;">
                <div v-if="imageItemList2[7]" style="height: 200px; width: 200px; margin-right: 20px;">
                  <v-img :src="imageItemList2[7]" height="200px" width="200px" style="object-fit: contain;" eager></v-img>
                </div>

                <div v-if="imageItemList2[8]" style="height: 200px; width: 268px;">
                  <v-img :src="imageItemList2[8]" height="200px" width="268px" style="object-fit: contain;" eager></v-img>
                </div>
              </v-row> -->
              <h3 class="styleH4">{{datasets[31].content}}</h3>
              <h3 class="styleT3" :style="{'font-style' : (language=='en') ? 'italic' : ''}">{{datasets[32].content}}</h3>
              <h4 class="styleT4 text-center">{{datasets[33].content}}</h4>
            </v-col>
          </v-row>
        </v-row>
      </div>
      <v-row v-if="datasets.length != 0" class="ma-0 pa-0" style="width:100%; justify-content:center">
        <div class="ma-0 pt-5 pb-0 px-5 col-11 col-md-6" style="justify-content:center">
          <h1 class="styleH4">{{datasets[34].content}}</h1>
          <!-- <h1 class="styleT3 my-1">{{datasets[17].content}}</h1> -->
          <div class="my-5" style="width:100%">
            <v-img :src="(language == 'cn') ? images.location_cn : (language == 'en') ? images.location_en : images.location_tw" eager></v-img>
          </div>
        </div>
        <!-- <v-row class="ma-0 px-0 py-2 mb-5" style="width:100%; justify-content:center; background-size: 500px; background-position: center top" 
        :style="{backgroundImage:`url(${images.map})`}">
          <v-row class="ma-0 py-0 px-4 col-12 col-md-6" style="justify-content:center">
            
            <v-col class="col-12 col-md-4 pa-2 pb-0">
              <div class="mb-1 py-1" style="border: 2px solid #686868" @click="openLink('China')">
                <h2 class="styleH4 py-2">{{$t('__linkChina')}}</h2>
              </div>
              <div class="mb-1 py-1" style="border: 2px solid #686868" @click="openLink('Malaysia')">
                <h2 class="styleH4 py-2">{{$t('__linkMalaysia')}}</h2>
              </div>
              <div class="mb-1 py-1" style="border: 2px solid #686868" @click="openLink('Cambodia')">
                <h2 class="styleH4 py-2">{{$t('__linkCambodia')}}</h2>
              </div>
              <div class="py-1" style="border: 2px solid #686868" @click="openLink('Philippines')">
                <h2 class="styleH4 py-2">{{$t('__linkPhilippines')}}</h2>
              </div>
            </v-col>
            <v-col class="col-6 col-md-4 pa-2 pb-0">
              <div style="border: 2px solid #686868; height:100%; cursor: pointer" @click="openLink('Singapore')">
                <v-row class="ma-0 mx-4" justify="center">
                  <div class="ma-5" style="width:60%">
                    <v-img :src="images.mapSingapore" eager></v-img>
                  </div>
                </v-row>
                <h2 class="styleH4">{{$t('__linkSingapore')}}</h2>
                <h3 class="styleH6 pb-2">{{$t('__linkSingaporeContent')}}</h3>
              </div>
            </v-col>
            <v-col class="col-6 col-md-4 pa-2 pb-0">
              <div style="border: 2px solid #686868; height:100%; cursor: pointer" @click="openLink('Taiwan')">
                <v-row class="ma-0 mx-4" justify="center">
                  <div class="ma-5" style="width:60%">
                    <v-img :src="images.mapTaiwan" eager></v-img>
                  </div>
                </v-row>
                <h2 class="styleH4">{{$t('__linkTaiwan')}}</h2>
                <h3 class="styleH6 pb-2">{{$t('__linkTaiwanContent')}}</h3>
              </div>
            </v-col>
          </v-row>
        </v-row> -->
      </v-row>
      <div style="width: 100%">
        <MainFooter ref="MainFooter" />
      </div>
    </div>
  </div>
</template>

<script>
import MainFooter from '@/components/MainFooter.vue'
import i18n from '@/plugins/i18n'
export default {
  name: 'AboutMyCal',
  components: {MainFooter},
  mounted() {
    this.language = i18n.locale
    Promise.all([
      new Promise((resolve)=>{resolve(this.getDatasetWebsiteCarousel())}),
      new Promise((resolve)=>{resolve(this.getDataset())}),
    ]).then(()=>{
      setTimeout(() => { this.initDone = true; }, 1500); //delay 0.1s
    })
  },
  data:() => ({
    websiteCarousel:[],
    datasets:[],
    language:'',
    initDone: false,
    innerWidth: 0,
    images:{
      vegetables: require('@/assets/image/vegetables.gif'),
      map: require('@/assets/image/map.png'),
      mapSingapore: require('@/assets/image/mapSingapore.png'),
      mapTaiwan: require('@/assets/image/mapTaiwan.png'),
      location_tw: require('@/assets/image/location_tw.png'),
      location_en: require('@/assets/image/location_en.png'),
      location_cn: require('@/assets/image/location_cn.png'),
    },
    imageCarouselList: [],
    imageItemList2: ['','','','','','','','','',''],
  }),
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
    this.$root.$on('language',(value)=>{
      this.language = value
    })
  },
  watch:{
    "language":function(){
      if(this.initDone){
        this.changeLanguageSel(this.websiteCarousel)
        this.changeLanguageSel(this.datasets)
      }
    },
  },
  methods:{
    calculateWindows(){
      this.innerWidth = window.innerWidth
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    goTop() {
      document.documentElement.scrollTop = 0;
    },
    changeLanguageSel(list){

      for(var j=0; j<list.length; j++){
        this.$set(list[j], 'content', list[j][this.language + 'Content'])
        this.$set(list[j], 'contentImage', list[j][this.language + 'ContentImage'])
      }

      //update imageCarouselList
      if(this.websiteCarousel.length > 0){
        this.imageCarouselList = []
        for(var i=0; i<this.websiteCarousel.length; i++){
          if(this.websiteCarousel[i][this.language + 'Content'] && this.websiteCarousel[i][this.language + 'Content'] != ''){
            this.imageCarouselList.push(this.$Global.getUrlImage() + this.websiteCarousel[i][this.language + 'Content'])
          }
        }
      }
      
      //update imageItemList2
      if(this.datasets.length > 0){
        this.imageItemList2[0] = this.datasets[2][this.language + 'ContentImage']
        this.imageItemList2[1] = this.datasets[4][this.language + 'ContentImage']
        this.imageItemList2[2] = this.datasets[8][this.language + 'ContentImage']
        this.imageItemList2[3] = this.datasets[12][this.language + 'ContentImage']
        this.imageItemList2[4] = this.datasets[16][this.language + 'ContentImage']
        this.imageItemList2[5] = this.datasets[20][this.language + 'ContentImage']
        this.imageItemList2[6] = this.datasets[24][this.language + 'ContentImage']
        this.imageItemList2[7] = this.datasets[29][this.language + 'ContentImage']
        this.imageItemList2[8] = this.datasets[30][this.language + 'ContentImage']
        this.imageItemList2[9] = this.datasets[36][this.language + 'ContentImage']
      }
    },
    getDatasetWebsiteCarousel(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/WebsiteCarousel',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.websiteCarousel = response.data.data
      }).catch(()=>{
      }).finally(()=>{
        for(var i=0; i<self.websiteCarousel.length; i++){
          let tmp1 = self.websiteCarousel[i]
          if(tmp1.twContent){
            self.$set(tmp1, 'twContentImage', this.$Global.getUrlImage() + tmp1.twContent)
          }
          if(tmp1.cnContent){
            self.$set(tmp1, 'cnContentImage', this.$Global.getUrlImage() + tmp1.cnContent)
          }
          if(tmp1.enContent){
            self.$set(tmp1, 'enContentImage', this.$Global.getUrlImage() + tmp1.enContent)
          }
        }
        self.changeLanguageSel(self.websiteCarousel)
      })
    },
    getDataset(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/CorporateConsultant',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.datasets = []
        self.datasets = response.data.data
      }).catch(()=>{
      }).finally(()=>{
        for(var i=0; i<self.datasets.length; i++){
          let tmp1 = self.datasets[i]
          if(self.datasets[i].informationType == '3'){
            if(tmp1.twContent){
              self.$set(tmp1, 'twContentImage', this.$Global.getUrlImage() + tmp1.twContent)
            }
            if(tmp1.cnContent){
              self.$set(tmp1, 'cnContentImage', this.$Global.getUrlImage() + tmp1.cnContent)
            }
            if(tmp1.enContent){
              self.$set(tmp1, 'enContentImage', this.$Global.getUrlImage() + tmp1.enContent)
            }
          }
        }
        self.changeLanguageSel(self.datasets)
      })
    },
    openLink(info){
      if(info == 'Singapore'){
        window.open('https://tw.yahoo.com/')
      }else if(info == 'Taiwan'){
        window.open('http://52.194.186.142/MyCal/#/homePage')
      }else if(info == 'China'){
        window.open('https://www.google.com.tw/?hl=zh_TW')
      }else if(info == 'Malaysia'){
        window.open('https://www.google.com/maps')
      }else if(info == 'Cambodia'){
        window.open('https://meet.google.com/')
      }else if(info == 'Philippines'){
        window.open('https://news.google.com/home')
      }
    },
  }
}
</script>

