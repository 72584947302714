<template>
  <v-row class="ma-0" style="justify-content:center">
    <v-dialog v-model="active" :persistent="fix" max-width="400">
      <v-card class="pa-3">
        <div class="close-dialog-btn mt-2" style="border-radius:14px">
          <v-icon v-if="!fix" size="28px" color="#D8DDE4" @click="close">mdi-close</v-icon>
        </div>
        <v-card-title class="text-h5 my-1" style="font-weight:800">
          {{title}}
        </v-card-title>
        <v-card-text class="text-left mt-1" style="font-weight:600; height:80px; font-size:15px">
          {{content}}
        </v-card-text>
        <v-row class="ma-0" style="justify-content:center">          
          <v-card-actions>
            <v-btn outlined color="#FFF" style="width: 150px; height:46px; border-radius: 10px; background:#4880C8"
            @click="save">
              {{$t('__confirm')}}
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import i18n from '../plugins/i18n'
  export default {
    name:'ShowMsg',
    props:['onSave'],
    data () {
      return {
        active: false,
        title: '',
        content: '',
        onSaveEn: false,
        fix: false,
        info: '',
      }
    },
    methods:{
      open(type = null, title = null, content = null, onSaveEn = false, fix = false, info = null){
        this.active = true
        this.onSaveEn = onSaveEn
        this.fix = fix
        this.info = info
        switch(type){
          case 0:
          default:
            this.title = title
            this.content = content
            break
          case 1:
            this.title = i18n.t('__requiredTitle')
            this.content = i18n.t('__requiredContent')
            break
          case 2:
            this.title = i18n.t('__newTitle')
            this.content = i18n.t('__newContent')
            break
          case 3:
            this.title = i18n.t('__revisedTitle')
            this.content = i18n.t('__revisedContent')
            break
          case 4:
            this.title = i18n.t('__deleteTitle')
            this.content = i18n.t('__deleteContent')
            break
          case 5:
            this.title = i18n.t('__importTitle')
            this.content = i18n.t('__importContent')
            break
          case 6:
            this.title = i18n.t('__sendTitle')
            this.content = i18n.t('__sendContent')
            break
          case 7:
            this.title = i18n.t('__applyTitle')
            this.content = i18n.t('__applyContent')
            break
          case 8:
            this.title = i18n.t('__couponTitle')
            this.content = i18n.t('__couponContent')
            break
          case 9:
            this.title = i18n.t('__couponTitle2')
            this.content = i18n.t('__couponContent2')
            break
          case 11:
            this.title = i18n.t('__requiredTitle')
            this.content = i18n.t('__privacyApplyContent')
            break
          case 12:
            this.title = i18n.t('__privacyTitle')
            this.content = i18n.t('__privacyContent')
            break
          case 13:
            this.title = i18n.t('__loginTitle')
            this.content = i18n.t('__loginContent')
            break
          case 14:
            this.title = i18n.t('__couponTitle3')
            this.content = i18n.t('__couponContent3')
            break
          case 20:
            this.title = i18n.t('__imageTitle')
            this.content = i18n.t('__imageContent')
            break
          case 21:
            this.title = i18n.t('__imageSizeTitle')
            this.content = i18n.t('__imageSize50Content')
            break
          case 22:
            this.title = i18n.t('__imageSizeTitle')
            this.content = i18n.t('__imageSize80Content')
            break
          case 30:
            this.title = i18n.t('__applyDietTitle')
            this.content = i18n.t('__applyDietContent')
            break
        }
      },
      close(){
        this.active = false
      },
      save(){
        if(this.onSaveEn){
          this.onSave(this.info)
        }
        this.close()
      }
    }
  }
</script>