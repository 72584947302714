<template>
  <div>
    <div v-show="!initDone">
      <v-row class="ma-0" style="justify-content:center; height:100%; background:#FBFBFB" :style="[{'height' : (innerWidth > 1125) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]">
        <div class="pt-8" style="width:90%; min-height:400px; max-width:600px;">
          <v-img :src="images.vegetables" eager></v-img>
        </div>
      </v-row>
    </div>
    <div v-show="initDone" id="router" class="pt-10" :style="[{'height' : (innerWidth > 959) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]" style="position: relative; z-index: 1">
      <v-row v-if="datasets.length != 0" class="ma-0" style="justify-content:center">
        <div style="width: 120px; position:absolute; top: 40px ;left: -50px; z-index: -1">
          <v-img :src="images.vegetables1" style="animation: oxxo 2s linear infinite"></v-img>
        </div>
        <div style="width: 120px; position:absolute; bottom: 0px ;left: -30px; z-index: -1">
          <v-img :src="images.vegetables2" style="animation: oxxo 2s linear infinite"></v-img>
        </div>
        <div style="width: 120px; position:absolute; top: 20px ;right: -30px; z-index: -1">
          <v-img :src="images.vegetables3" style="animation: oxxo 2s linear infinite"></v-img>
        </div>
        <div style="width: 50px; position:absolute; bottom: -50px ;right: 5%; z-index: -1">
          <v-img :src="images.vegetables4" style="animation: oxxo 2s linear infinite"></v-img>
        </div>
        <div style="width: 120px; position:absolute; bottom: -400px ;right: -20px; z-index: -1">
          <v-img :src="images.vegetables5" style="animation: oxxo 2s linear infinite"></v-img>
        </div>
        <v-row class="ma-0 pa-0 col-11 col-md-7" style="justify-content:center">
          <div v-if="imageItemList[0]" style="width:100%">
            <v-img :src="imageItemList[0]" eager></v-img>
          </div>
        </v-row>
        <v-row class="pa-0 col-11 col-md-7" style="margin: 2% 0%; justify-content:center; background:#FFF">
          <v-col cols="4" class="ma-0 pa-0">
            <v-row class="ma-0" style="justify-content:center">
              <div v-if="imageItemList[1]" style="width: 65%">
                <v-img :src="imageItemList[1]" eager></v-img>
              </div>
            </v-row>
            <h1 class="styleH4">{{datasets[2].content}}</h1>
            <h2 class="styleT4">{{datasets[3].content}}</h2>
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-row class="ma-0" style="justify-content:center">
              <div v-if="imageItemList[2]" style="width: 65%">
                <v-img :src="imageItemList[2]" eager></v-img>
              </div>
            </v-row>
            <h1 class="styleH4">{{datasets[5].content}}</h1>
            <h2 class="styleT4">{{datasets[6].content}}</h2>
          </v-col>
          <v-col cols="4" class="ma-0 pa-0">
            <v-row class="ma-0" style="justify-content:center">
              <div v-if="imageItemList[3]" style="width: 65%">
                <v-img :src="imageItemList[3]" eager></v-img>
              </div>
            </v-row>
            <h1 class="styleH4">{{datasets[8].content}}</h1>
            <h2 class="styleT4">{{datasets[9].content}}</h2>
          </v-col>
        </v-row>
        <v-row class="col-11 col-md-7" style="margin: 0% 0%; padding: 2% 0%; justify-content:center; border-top: 4px solid #0C80BD">
          <v-col class="ma-0 pa-0 col-11 col-md-8">
            <youtube player-width="100%" :video-id="videoId" eager></youtube>
          </v-col>
          <v-col class="ma-0 pa-0 px-8 col-11 col-md-4 text-left" align-self="center">
            <h1 class="styleH4">{{datasets[11].content}}</h1>
            <h2 class="styleT4">{{datasets[12].content}}</h2>
            <v-row class="ma-0 pt-2" style="justify-content:space-around">
              <div class="pa-0" style="width:48%; cursor:pointer" @click="openLink('googlePlay')">
                <v-img :src="images.googlePlay" alt="googlePlay_link" eager></v-img>
              </div>
              <div class="pa-0" style="width:48%; cursor:pointer" @click="openLink('appStore')">
                <v-img :src="images.appStore" alt="appStore_link" eager></v-img>
              </div>
            </v-row>
          </v-col>
        </v-row>
        <div style="width: 100%">
          <MainFooter ref="MainFooter" />
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import MainFooter from '@/components/MainFooter.vue'
import i18n from '@/plugins/i18n'
export default {
  name: 'HomePageDetail',
  components: {MainFooter},
  props: {},
  data:() => ({
    language:'',
    datasets:[],
    dataset:{},
    innerWidth: 0,
    images:{
      googlePlay: require('@/assets/image/googlePlay.png'),
      appStore: require('@/assets/image/appStore.png'),
      vegetables: require('@/assets/image/vegetables.gif'),
      vegetables1: require('@/assets/image/vegetables1.png'),
      vegetables2: require('@/assets/image/vegetables2.png'),
      vegetables3: require('@/assets/image/vegetables3.png'),
      vegetables4: require('@/assets/image/vegetables4.png'),
      vegetables5: require('@/assets/image/vegetables5.png'),
    },

    videoId:'',
    imageItemList: ['','','',''],
    initDone:false,
  }),
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
    this.$root.$on('language',(value)=>{
      this.language = value
    })
  },
  mounted() {
    this.language = i18n.locale
    this.getDataset()
    setTimeout(() => {
      this.initDone = true
    }, 1000);
  },
  watch:{
    "language":function(){
      this.changeLanguageSel()
    },
  },
  methods:{
    calculateWindows(){
      this.innerWidth = window.innerWidth
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    changeLanguageSel(){
      for(var j=0; j<this.datasets.length; j++){
        this.$set(this.datasets[j], 'content', this.datasets[j][this.language + 'Content'])
        this.$set(this.datasets[j], 'contentImage', this.datasets[j][this.language + 'ContentImage'])
        if(j == 10){
          this.videoId = this.$youtube.getIdFromURL(this.datasets[10].content)
        }
      }
        
      //update imageItemList
      if(this.datasets.length > 0){
        this.imageItemList[0] = this.datasets[0][this.language + 'ContentImage']
        this.imageItemList[1] = this.datasets[1][this.language + 'ContentImage']
        this.imageItemList[2] = this.datasets[4][this.language + 'ContentImage']
        this.imageItemList[3] = this.datasets[7][this.language + 'ContentImage']
      }
    },
    getDataset(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/AppIntroduction',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.datasets = []
        self.datasets = response.data.data
      }).catch(()=>{
      }).finally(()=>{
        for(var i=0; i<self.datasets.length; i++){
          let tmp1 = self.datasets[i]
          if(tmp1.twContent){
            self.$set(tmp1, 'twContentImage', this.$Global.getUrlImage() + tmp1.twContent)
          }
          if(tmp1.cnContent){
            self.$set(tmp1, 'cnContentImage', this.$Global.getUrlImage() + tmp1.cnContent)
          }
          if(tmp1.enContent){
            self.$set(tmp1, 'enContentImage', this.$Global.getUrlImage() + tmp1.enContent)
          }
        }
        self.changeLanguageSel()
      })
    },
    openLink(info){
      if(info == 'googlePlay'){
        window.open('https://play.google.com')
      }else if(info == 'appStore'){
        window.open('https://www.apple.com/tw/app-store/')
      }
    },
  }
}
</script>

