<template>
  <div :style="{backgroundImage:`url(${images.background})`}" style="background-position: left bottom; background-repeat: repeat-x">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true">
    </loading>
    <div id="router" class="pt-10" :style="[{'height' : (haveToken) ? 'calc(100vh)' : (innerWidth > 1125) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]">
      <v-row style="justify-content:center" :style="[{'margin' : (innerWidth > 959) ? '1% 8%' : '1% 1%'}]">
        <v-col class="col-12 col-md-6 px-4" align="center">
          <h4 class="styleH4">{{$t('__storageUpgrade')}}</h4>
          <v-row class="my-1 pa-3 text-left weight600">
            <div v-html="upgradeInfo" style="width:100%"></div>
          </v-row>
          <div id="box" class="mb-0" style="width:100%">
            <v-row class="pa-3 pb-8">
              <v-icon class="ma-0 pa-0" size="30" style="color:#4880C8">mdi-menu-right</v-icon>
              <h4 class="DarkBlue pt-1">{{$t('__pricePurchasePlan')}}</h4>
            </v-row>
            <v-col class="pa-0">
              <v-text-field class="text_field" dense readonly background-color="#F4F8F9" outlined
                :label="$t('__memberExpiration')" v-model="myCalExpiration">
              </v-text-field>

              <v-radio-group :label="$t('__priceOrderProgram')" v-if="upgradeDetailList.language != 0" 
                class="ma-0" dense v-model="datasets.orderProgram">
                <v-row class="ma-0 mt-1">
                  <v-radio v-for="(data, index) in upgradeDetailList" :key = "index" :value = "data.useMonth.toString()"
                  class="pr-1 ma-1 pa-0" color="#4880C8" style="max-height: 20px !important">
                    <template v-slot:label>
                      <span class="pt14" style="color:#000000">{{data.title}}</span>
                    </template>
                  </v-radio>
                </v-row>
              </v-radio-group>

              <v-text-field class="text_field" dense readonly background-color="#F4F8F9" outlined
                :label="$t('__priceProgramFee')" v-model="datasets.programFee">
              </v-text-field>

              <v-text-field class="text_field" dense readonly background-color="#F4F8F9" outlined
                :label="$t('__pricePeriod')" v-model="usedDatesInfo">
              </v-text-field>

              <!-- <v-row class="ma-0">
                <v-col class="px-0 col-2">
                  <div class="text-right pt-2 pt14">{{$t('__pricePeriod')}}</div>
                </v-col>
                <v-col v-if="innerWidth >= 500" class="col-4">
                  <v-text-field class="text_field" dense readonly background-color="#F4F8F9" outlined v-model="datasets.startDate"></v-text-field>
                </v-col>
                <v-col v-if="innerWidth >= 500" class="col-1" align-self="center" style="text-align: center">
                  ~
                </v-col>
                <v-col v-if="innerWidth >= 500" class="col-4">
                  <v-text-field class="text_field" dense readonly background-color="#F4F8F9" outlined v-model="datasets.endDate"></v-text-field>
                </v-col>

                <v-col v-if="innerWidth < 500" class="col-9">
                  <v-text-field class="text_field" dense readonly background-color="#F4F8F9" outlined v-model="usedDatesInfo"></v-text-field>
                </v-col>
              </v-row> -->

              <v-radio-group :label="$t('__pricePaymentMethod')" class="ma-0" dense v-model="datasets.prPaymentMethod">
                <v-row class="ma-0 mt-1">
                  <v-radio v-for="(data, index) in paymentList" :key = index :value = "data.valuev"
                  class="pr-1 ma-1 pa-0" color="#4880C8" style="max-height: 20px !important">
                    <template v-slot:label>
                      <span class="pt14" style="color:#000000">{{data.content}}</span>
                    </template>
                  </v-radio>
                </v-row>
              </v-radio-group> 
              <v-radio-group :label="$t('__pricePaymentfrequency')" class="ma-0" dense v-model="datasets.frequency">
                <v-row class="ma-0 mt-1">
                  <v-radio v-for="(data, index) in (datasets.prPaymentMethod === '1' ? frequencyList : [frequencyList[1]])" :key="index" :value="data.valuev"
                    class="pr-1 ma-1 pa-0" color="#4880C8" style="max-height: 20px !important">
                    <template v-slot:label>
                      <span class="pt14" style="color:#000000">{{data.content}}</span>
                    </template>
                  </v-radio>
                </v-row>
              </v-radio-group>
              <v-row class="ma-0" style="justify-content:center">
                <v-btn class="styleBtn1 border" outlined style="max-width:50%; color:#FFF; background:#4880C8" @click="testNewPay()">
                  <h5 class="center">{{$t('__priceImmediatePayment')}}</h5>
                </v-btn>
              </v-row>
            </v-col>
          </div>
        </v-col>
        <v-col class="col-12 col-md-6 col-lg-5 px-4">
          <v-row v-for="(data,index) in upgradeDetailList" :key="index" class="mx-0 my-3 pa-0" style="border: 2px solid #4880C8; border-radius: 8px;">
            <div class="text-center ma-0 pa-4 pa-auto" style="position:relative; background:#4880C8; color:#FFF"
            :style="{'width' : (innerWidth >= 490) ? '160px' : '96px'}">
              <v-row class="ma-0 pr-2" style="width:100%" justify="center">
                <h2 style="width:70px; line-height:36px" :style="{'font-size' : (language=='en') ? '1em' : '1.5em'}">{{data.title}}</h2>
              </v-row>
              <div v-if="innerWidth >= 490" style="width:76px; position:absolute; top:13px; left:122px">
                <v-img :src="images['img'+(index+1)]"></v-img>
              </div>
            </div>
            <v-col class="text-center ma-0 pa-0" align-self="center">
              <!-- <h3 class="" style="line-height:28px">{{$t('__priceOrderProgram')}}</h3> -->
              <h3 style="line-height:28px">{{data.name}} {{data.currency}} {{data.amount}}</h3>
            </v-col>
          </v-row>
          <h4 class="text-left weight600">{{$t('__priceOrderDescription')}}</h4>
        </v-col>
      </v-row>
    </div>
    <div v-if="haveToken" style="position: absolute; top:10px; left:10px;"> 
      <div class="ma-1" style="background:#222; border-radius: 30px; height:36px; width:36px; opacity: 0.7; z-index:5"
      @click="redirectPath('/storageUpgrade')">
        <v-icon class="ma-1" size="28px" color="#FFF">mdi-menu-left</v-icon>
      </div>
      <v-img :src="datasets.mainImageImage" width=500 alt="action_04"></v-img>
    </div>
    <ShowMsg ref="ShowMsg" :onSave="onShowMsg"/>
  </div>
</template>

<script>
import ShowMsg from '@/dialogs/ShowMsg'
import i18n from '@/plugins/i18n'
import moment from 'moment'
import Loading from 'vue-loading-overlay'
import CryptoJS from "crypto-js"
import 'vue-loading-overlay/dist/vue-loading.min.css'
export default {
  name: 'storageUpgradeApply',
  props: {},
  components: {ShowMsg,Loading},
  mounted(){
    this.language = i18n.locale
    this.getStorageUpgradeInfo()
    this.getStorageUpgradeDetail()
    this.getPaymentList()
    this.getFrequencyList()
    this.getExpirationList()
  },
  data:() => ({
    formData: {
      RespondType: 'JSON',
      Version: '1.0',
      TimeStamp: Math.floor(Date.now() / 1000),
      LangType: 'zh-Tw',
      MerOrderNo: 'myorder' + Math.floor(Date.now() / 1000),
      ProdDesc: 'Test commission',
      PeriodAmt: '10',
      PeriodType: 'M',
      PeriodPoint: '05',
      PeriodStartType: '2',
      PeriodTimes: '12',
      PayerEmail: 'test@neweb.com.tw',
      PaymentInfo: 'Y',
      OrderInfo: 'N',
      EmailModify: '1',
      NotifyURL: 'https://webhook.site/b728e917-1bf7-478b-b0f9-73b56aeb44e0',
    },
    key: 'XcyLKO6EMpPcCDYjZJ8AuShOS8YFXeH8',
    iv: 'CUG4A63mjhhqMDZP',
    mid: 'MS355146024',
    language:'',
    orderProgram:'',
    datasets:{
      frequency: '1',
      prPaymentMethod: '1',
    },
    haveToken: false,
    myCalExpiration:'',
    usedDatesInfo:'',
    upgradeInfo:'',
    upgradeInfoList:[],
    upgradeDetail:[],
    upgradeDetailList:[],
    paymentList:[],
    frequencyList:[],
    innerWidth: 0,
    indexHover:'',
    images:{
      background: require('@/assets/image/background-2000.png'),
      img1: require('@/assets/image/img1.png'),
      img2: require('@/assets/image/img2.png'),
      img3: require('@/assets/image/img3.png'),
      img4: require('@/assets/image/img4.png'),
    },
    isLoading: false,
    content:'',

    menu: false,
    // pickerDate:(new Date().getFullYear()) + '-1-1',
    requiredField:['orderProgram','programFee','startDate','endDate','prPaymentMethod'],
  }),
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
    this.$root.$on('language',(value)=>{
      this.language = value
    })
  },
  watch:{
    "language":function(){
      this.changeLanguageSel()
      this.changeDatasetSel()
      this.changeListSel(this.paymentList)
      this.changeListSel(this.frequencyList)
    },
    "datasets.orderProgram":function(){
      let value = this.datasets.orderProgram
      var index = this.upgradeDetailList.findIndex(function(item){return item.useMonth == value})
      if(index != -1){
        this.datasets.programFee = this.upgradeDetailList[index].amount
      }
      this.usedDates()
    },
    "datasets.startDate":function(){
      this.usedDates()
    },
    "datasets.prPaymentMethod":function(){
      if(this.datasets.prPaymentMethod !== '1'){
        setTimeout(() => { this.datasets.frequency = '2' }, 1); //delay 0.1s
      }
    },
  },
  methods:{
    calculateWindows(){
      if(this.$cookie.get('token')){
        this.haveToken = true
      }
      this.innerWidth = window.innerWidth
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    allowedDates(val){
      // console.log('[allowedExamDates] date: ' + val)
      let dateMin = moment().add(0, 'days').format('YYYY-MM-DD')
      return (new Date(val) >= new Date(dateMin))
    },
    getExpirationList(){
      var allData = this.$Global.copy(this.datasets)
      allData = this.$Global.jsonConcat(allData,{'memberId': sessionStorage.getItem('myCalId')})
      var self = this
      var request = {
        method: 'POST',
        url: '/mycalMemberExpiration',
        data: this.Qs.stringify(allData)
      }
      this.$http(request).then(function(response) {
        self.myCalExpiration = response.data.msg
        // self.$refs.ShowMsg.open(7,'','',true,true)
      }).catch(()=>{
        self.$refs.ShowMsg.open(0,'系統錯誤','請聯繫系統管理人員，編號為Cot-8121')
      }).finally(()=>{
        if(new Date() >= new Date(self.myCalExpiration)){
          this.datasets.startDate = moment(new Date()).format('YYYY-MM-DD')
        }else{
          this.datasets.startDate = self.myCalExpiration
        }
      })
    },
    usedDates(){
      if(!this.datasets.orderProgram || !this.datasets.startDate){
        return
      }
      let value = this.datasets.orderProgram
      var index = this.upgradeDetailList.findIndex(function(item){return item.useMonth == value})
      if(index != -1){
        var months = this.upgradeDetailList[index].useMonth.toString()
      }
      this.datasets.endDate = moment(this.datasets.startDate).add(months, 'months').format('YYYY-MM-DD')
      this.usedDatesInfo = this.datasets.startDate + ' ~ ' + this.datasets.endDate
    },
    changeListSel(List){
      for(var i=0; i<List.length; i++){
        if(this.language=='tw'){
          this.$set(List[i], 'content', List[i].twName)
        }else if(this.language=='cn'){
          this.$set(List[i], 'content', List[i].cnName)
        }else if(this.language=='en'){
          this.$set(List[i], 'content', List[i].enName)
        }
      }
    },
    changeLanguageSel(){
      if(this.language=='tw'){
        this.upgradeInfo = this.upgradeInfoList.twContent
      }else if(this.language=='cn'){
        this.upgradeInfo = this.upgradeInfoList.cnContent
      }else if(this.language=='en'){
        this.upgradeInfo = this.upgradeInfoList.enContent
      }
    },
    changeDatasetSel(){
      if(!this.upgradeDetail.twContent){
        return
      }
      if(this.language=='tw'){
        this.upgradeDetailList = JSON.parse(this.upgradeDetail.twContent)
      }else if(this.language=='cn'){
        this.upgradeDetailList = JSON.parse(this.upgradeDetail.cnContent)
      }else if(this.language=='en'){
        this.upgradeDetailList = JSON.parse(this.upgradeDetail.enContent)
      }
    },
    getStorageUpgradeInfo(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/StorageUpgradeInfo',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.upgradeInfoList = []
        self.upgradeInfoList = response.data.data[0]
      }).catch(()=>{
      }).finally(()=>{
        self.changeLanguageSel()
      })
    },
    getStorageUpgradeDetail(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/StorageUpgradeDetail',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.upgradeDetail = []
        self.upgradeDetail = response.data.data[0]
      }).catch(()=>{
      }).finally(()=>{
        self.changeDatasetSel()
      })
    },
    getPaymentList(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/typevByCategory/paymentMethod',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.paymentList = response.data.data
      }).catch(()=>{
      }).finally(()=>{
        self.changeListSel(self.paymentList)
      })
    },
    getFrequencyList(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/typevByCategory/frequency',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.frequencyList = response.data.data
      }).catch(()=>{
      }).finally(()=>{
        self.changeListSel(self.frequencyList)
      })
    },
    testNewPay() {
      if(!this.$Global.checkForm(this.datasets,this.requiredField)){
        this.$refs.ShowMsg.open(1)
        return
      }
      if(this.datasets.frequency == '1'){
        console.log('自動續約')
        // 先取號再執行
        this.newOrderRecurring()
      }else if(this.datasets.frequency == '2'){
        console.log('單期購買')
        // 先取號再執行
        this.newOrderSingle()
      }
    },
    newOrderRecurring(){
      console.log('newOrderRecurring')
      //this.$refs.ShowMsg.open(0,'系統提示','付款系統建置中，請耐心等待。')
      
      // this.isLoading = true

      var allData = this.$Global.copy(this.datasets)
      
      allData = this.$Global.jsonConcat(allData,{'languageSystem': this.language})
      allData = this.$Global.jsonConcat(allData,{'memberId': sessionStorage.getItem('myCalId')})
      allData = this.$Global.jsonConcat(allData,{'memberName': sessionStorage.getItem('myCalName')})
      allData = this.$Global.jsonConcat(allData,{'paymentStatus': '1'})
      allData = this.$Global.jsonConcat(allData,{'note1': sessionStorage.getItem('myCalEmail')})

      allData = this.$Global.jsonConcat(allData, {'RespondType': 'JSON'}) //回傳格式
      allData = this.$Global.jsonConcat(allData, {'TimeStamp': moment().unix().toString()}) //時間戳記
      allData = this.$Global.jsonConcat(allData, {'Version': '1.5'}) //串接程式版本 1.5
      allData = this.$Global.jsonConcat(allData, {'LangType': (this.language=='en') ? 'en' : 'zh-Tw'}) //語系
      allData = this.$Global.jsonConcat(allData, {'MerOrderNo': ''}) //商店訂單編號
      allData = this.$Global.jsonConcat(allData, {'ProdDesc': this.datasets.orderProgram + '個月方案-' + this.datasets.programFee + '元-定期定額'}) //產品名稱
      allData = this.$Global.jsonConcat(allData, {'PeriodAmt': this.datasets.programFee}) //委託金額
      allData = this.$Global.jsonConcat(allData, {'PeriodType': 'M'}) //#週期類別 - D=固定天期制 / W=每週 / M=每月 / Y=每年
      allData = this.$Global.jsonConcat(allData, {'PeriodPoint': '05'}) //#交易週期-授權時間
      allData = this.$Global.jsonConcat(allData, {'PeriodStartType': '2'}) //交易模式
      allData = this.$Global.jsonConcat(allData, {'PeriodTimes': '12'}) //#授權期數
      allData = this.$Global.jsonConcat(allData, {'PeriodFirstdate': ''}) //#首期授權日
      allData = this.$Global.jsonConcat(allData, {'ReturnURL': 'https://rainbowapps.asia/#/storageUpgradeRecord'}) //返回商店網址
      allData = this.$Global.jsonConcat(allData, {'PeriodMemo': ''}) //備註說明
      allData = this.$Global.jsonConcat(allData, {'PayerEmail': sessionStorage.getItem('myCalEmail')}) //付款人電子信箱
      // allData = this.$Global.jsonConcat(allData, {'EmailModify': ''}) //付款人電子信箱是否開放修改-預設可修改
      allData = this.$Global.jsonConcat(allData, {'PaymentInfo': 'Y'}) //是否需顯示付款人資訊填寫欄位(包含付款人姓名、付款人電話、付款人手機) Ｙ＝是 / Ｎ＝否
      allData = this.$Global.jsonConcat(allData, {'OrderInfo': 'N'}) //是否需顯示收件人資訊填寫欄位(包含收件人姓名、收件人電話、收件人手機、收件人地址) Ｙ＝是 / Ｎ＝否
      allData = this.$Global.jsonConcat(allData, {'NotifyURL': 'https://rainbowapps.asia/api/common/lxCcppNotify'}) //每期授權結果通知網址 TODO: 更換成正式地址
      allData = this.$Global.jsonConcat(allData, {'BackURL': 'https://rainbowapps.asia/#/storageUpgradeRecord'}) //返回商店網址
      allData = this.$Global.jsonConcat(allData, {'UNIONPAY': '0'}) //信用卡銀聯卡啟用 是否啟用銀聯卡支付方式 1=啟用 / 0 或者未有此參數=不啟
      console.log('allData')
      console.log(allData)
      
      var self = this
      var request = {
        method: 'POST',
        url: '/orderRecurring',
        data: this.Qs.stringify(allData)
      }
      this.$http(request).then(function(response) {
        console.log('POST-response')
        console.log(response.data.msg) // 訂單號
        // allData.MerchantOrderNo = response.data.msg;
        allData.MerOrderNo = response.data.msg;
        // 將物件轉換為 URL 查詢字符串
        const dataString = new URLSearchParams(allData).toString();

        // 使用 AES-256-CBC 進行加密
        // 確保 key 為 32 字節，iv 為 16 字節
        const key = CryptoJS.enc.Utf8.parse(self.key.padEnd(32, '0')); // 32 字節
        const iv = CryptoJS.enc.Utf8.parse(self.iv.padEnd(16, '0'));  // 16 字節

        // 使用 AES-256-CBC 進行加密
        const encrypted = CryptoJS.AES.encrypt(
          CryptoJS.enc.Utf8.parse(dataString), // 確保 UTF-8 字串格式
          key,
          {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          }
        );
        const encryptedData = encrypted.ciphertext.toString(CryptoJS.enc.Hex);

        // 提交加密後的數據
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = 'https://ccore.newebpay.com/MPG/period';

        const inputMerchantID = document.createElement('input');
        inputMerchantID.type = 'hidden';
        inputMerchantID.name = 'MerchantID_';
        inputMerchantID.value = self.mid;
        form.appendChild(inputMerchantID);

        const tradeInfoData = document.createElement('input');
        tradeInfoData.type = 'hidden';
        tradeInfoData.name = 'PostData_';
        tradeInfoData.value = encryptedData;
        form.appendChild(tradeInfoData);

        // var shaData = "HashKey="+self.key+"&"+encryptedData+"&HashIV="+self.iv;
        // var shaData2 = CryptoJS.SHA256(shaData).toString(CryptoJS.enc.Hex).toUpperCase();
        // const tradeShaData = document.createElement('input');
        // tradeShaData.type = 'hidden';
        // tradeShaData.name = 'TradeSha';
        // tradeShaData.value = shaData2;
        // form.appendChild(tradeShaData);

        // const versionData = document.createElement('input');
        // versionData.type = 'hidden';
        // versionData.name = 'Version';
        // versionData.value = '2.0';
        // form.appendChild(versionData);

        document.body.appendChild(form);
        form.submit();
        self.isLoading = false
      }).catch(e=>{
        console.log('POST-catch')
        console.log(e)
        self.isLoading = false
      })
    },
    newOrderSingle() {
      this.isLoading = true

      var allData = this.$Global.copy(this.datasets)
      allData = this.$Global.jsonConcat(allData,{'languageSystem': this.language})
      allData = this.$Global.jsonConcat(allData,{'memberId': sessionStorage.getItem('myCalId')})
      allData = this.$Global.jsonConcat(allData,{'memberName': sessionStorage.getItem('myCalName')})
      allData = this.$Global.jsonConcat(allData,{'paymentStatus': '1'})
      allData = this.$Global.jsonConcat(allData,{'note1': sessionStorage.getItem('myCalEmail')})

      allData = this.$Global.jsonConcat(allData, {'MerchantID': 'MS355146024'}) //商店代號
      allData = this.$Global.jsonConcat(allData, {'RespondType': 'JSON'}) //回傳格式
      allData = this.$Global.jsonConcat(allData, {'MerchantOrderNo': ''}) //商店訂單編號
      allData = this.$Global.jsonConcat(allData, {'TimeStamp': moment().unix().toString()}) //時間戳記
      allData = this.$Global.jsonConcat(allData, {'Version': '2.0'}) //串接程式版本 2.0
      allData = this.$Global.jsonConcat(allData, {'LangType': (this.language=='en') ? 'en' : 'zh-tw'}) //語系
      allData = this.$Global.jsonConcat(allData, {'Amt': this.datasets.programFee}) //訂單金額
      allData = this.$Global.jsonConcat(allData, {'ItemDesc': (this.datasets.orderProgram + '個月方案-' + this.datasets.programFee + '元')}) //商品資訊
      allData = this.$Global.jsonConcat(allData, {'TradeLimit': '600'}) //交易有效時間
      // allData = this.$Global.jsonConcat(allData, {'ExpireDate': ''}) //繳費有效期限-非即時交易預設七天
      allData = this.$Global.jsonConcat(allData, {'ReturnURL': 'https://rainbowapps.asia/#/storageUpgradeRecord'}) //支付完成 返回商店網址
      allData = this.$Global.jsonConcat(allData, {'NotifyURL': 'https://rainbowapps.asia/api/common/lxNotify'}) //支付通知網址-正式地址
      // allData = this.$Global.jsonConcat(allData, {'NotifyURL': 'http://52.197.196.72/common/lxNotify'}) //支付通知網址-測試地址
      allData = this.$Global.jsonConcat(allData, {'CustomerURL': ''}) //商店取號網址
      allData = this.$Global.jsonConcat(allData, {'ClientBackURL': 'https://rainbowapps.asia/#/storageUpgradeRecord'}) //返回商店網址
      allData = this.$Global.jsonConcat(allData, {'Email': sessionStorage.getItem('myCalEmail')}) //付款人電子信箱
      // allData = this.$Global.jsonConcat(allData, {'EmailModify': ''}) //付款人電子信箱是否開放修改-預設可修改
      allData = this.$Global.jsonConcat(allData, {'LoginType': '0'}) //藍新金流會員-0-不須登入藍新金流會員
      allData = this.$Global.jsonConcat(allData, {'OrderComment': '請在購買前留意我的卡路里加值方案，因一經扣款後將無法申請退款'}) //商店備註
      allData = this.$Global.jsonConcat(allData, {'CREDIT': (this.datasets.prPaymentMethod == '1' ? '1' : '0')}) //信用卡一次付清啟用
      allData = this.$Global.jsonConcat(allData, {'LINEPAY': (this.datasets.prPaymentMethod == '3' ? '1' : '0')}) //LINE Pay
      allData = this.$Global.jsonConcat(allData, {'ImageUrl': ''}) //LINE Pay產品圖檔連結網址
      allData = this.$Global.jsonConcat(allData, {'VACC': (this.datasets.prPaymentMethod == '2' ? '1' : '0')}) //ATM 轉帳啟用
      var self = this
      var request = {
        method: 'POST',
        url: '/orderSingle',
        data: this.Qs.stringify(allData)
      }
      this.$http(request).then(function(response) {
        console.log('POST-response')
        console.log(response.data.msg) // 訂單號
        allData.MerchantOrderNo = response.data.msg;
        allData.MerOrderNo = response.data.msg;
        // 將物件轉換為 URL 查詢字符串
        const dataString = new URLSearchParams(allData).toString();

        // 使用 AES-256-CBC 進行加密
        // 確保 key 為 32 字節，iv 為 16 字節
        const key = CryptoJS.enc.Utf8.parse(self.key.padEnd(32, '0')); // 32 字節
        const iv = CryptoJS.enc.Utf8.parse(self.iv.padEnd(16, '0'));  // 16 字節

        // 使用 AES-256-CBC 進行加密
        const encrypted = CryptoJS.AES.encrypt(
          CryptoJS.enc.Utf8.parse(dataString), // 確保 UTF-8 字串格式
          key,
          {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          }
        );
        const encryptedData = encrypted.ciphertext.toString(CryptoJS.enc.Hex);

        // 提交加密後的數據
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = 'https://ccore.newebpay.com/MPG/mpg_gateway';

        const inputMerchantID = document.createElement('input');
        inputMerchantID.type = 'hidden';
        inputMerchantID.name = 'MerchantID';
        inputMerchantID.value = self.mid;
        form.appendChild(inputMerchantID);

        const tradeInfoData = document.createElement('input');
        tradeInfoData.type = 'hidden';
        tradeInfoData.name = 'TradeInfo';
        tradeInfoData.value = encryptedData;
        form.appendChild(tradeInfoData);

        var shaData = "HashKey="+self.key+"&"+encryptedData+"&HashIV="+self.iv;
        var shaData2 = CryptoJS.SHA256(shaData).toString(CryptoJS.enc.Hex).toUpperCase();
        const tradeShaData = document.createElement('input');
        tradeShaData.type = 'hidden';
        tradeShaData.name = 'TradeSha';
        tradeShaData.value = shaData2;
        form.appendChild(tradeShaData);

        const versionData = document.createElement('input');
        versionData.type = 'hidden';
        versionData.name = 'Version';
        versionData.value = '2.0';
        form.appendChild(versionData);

        document.body.appendChild(form);
        form.submit();
        self.isLoading = false
      }).catch(e=>{
        console.log('POST-catch')
        console.log(e)
        self.isLoading = false
      })
    },
    onShowMsg(){
      this.redirectPath('/storageUpgrade')
    },
    encryptData() {
      // 將物件轉換為 URL 查詢字符串
      const dataString = new URLSearchParams(this.formData).toString();
      console.log(dataString)

      // 使用 AES-256-CBC 進行加密
      // 確保 key 為 32 字節，iv 為 16 字節
      const key = CryptoJS.enc.Utf8.parse(this.key.padEnd(32, '0')); // 32 字節
      const iv = CryptoJS.enc.Utf8.parse(this.iv.padEnd(16, '0'));  // 16 字節

      // 使用 AES-256-CBC 進行加密
      const encrypted = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(dataString), // 確保 UTF-8 字串格式
        key,
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }
      );
      const hexCiphertext = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
      return hexCiphertext;
    },
    submitForm() {
      const encryptedData = this.encryptData();

      // 提交加密後的數據
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = 'https://ccore.newebpay.com/MPG/period';

      const inputMerchantID = document.createElement('input');
      inputMerchantID.type = 'hidden';
      inputMerchantID.name = 'MerchantID_';
      inputMerchantID.value = this.mid;
      form.appendChild(inputMerchantID);

      const inputPostData = document.createElement('input');
      inputPostData.type = 'hidden';
      inputPostData.name = 'PostData_';
      inputPostData.value = encryptedData;

      form.appendChild(inputPostData);
      document.body.appendChild(form);
      form.submit();
    },
  }
}
</script>

