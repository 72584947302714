<template>
  <v-footer class="pa-0 ma-0" style="height: 84px; background-color: #FFF">
    <v-row class="ma-0" style="width:100%; padding: 6px 8% 6px 8%; justify-content:center; background: linear-gradient(90deg, #69C4F5 27.46%, #51AAE2 97.43%)">
      <v-col class="col-12 col-md-6 text-left px-0" style="color:#FFF">
        <p class="pt-5">{{$t('__companyName')}}</p>
        <p>{{$t('__unifiedBusinessNo')}}</p>
        <p>{{$t('__companyAddress')}}</p>
        <p>{{$t('__companyAddress2')}}</p>
        <p class="pb-5">{{$t('__companyEmail')}}</p>
        <!-- <p v-if="innerWidth < 960">{{$t('__copyright')}}</p> -->
        <v-row v-if="innerWidth < 960" class="ma-0 mt-1" justify-content="flex-end">
          <div class="mr-2 pa-1" style="height:34px; width:34px; border-radius: 20px; border: 1px solid #FFF; cursor:pointer" @click="openLink('line')">
            <v-img :src="images.line" width=500 alt="line-icon"></v-img>
          </div>
          <div class="mr-2 pa-1" style="height:34px; width:34px; border-radius: 20px; border: 1px solid #FFF; cursor:pointer" @click="openLink('instagram')">
            <v-img :src="images.instagram" width=500 alt="instagram-icon"></v-img>
          </div>
          <div class="mr-1 pa-1" style="height:34px; width:34px; border-radius: 20px; border: 1px solid #FFF; cursor:pointer" @click="openLink('youtube')">
            <v-img :src="images.youtube" width=500 alt="youtube-icon"></v-img>
          </div>
          <!-- <v-col class="mr-1 pa-1" style="height:34px; max-width:156px; min-width:150px; border: 1px solid #FFF; cursor:pointer" @click="openLink('instagram')">
            <v-icon color=#FFF>mdi-instagram</v-icon>
            <span class="px-1 pt-1">MyCal_Taiwan</span>
          </v-col>
          <v-col class="mr-1 pa-1" style="height:34px; max-width:156px; min-width:150px; border: 1px solid #FFF; cursor:pointer" @click="openLink('youtube')">
            <v-icon color=#FFF>mdi-youtube</v-icon>
            <span class="px-1 pt-2">MyCal_Taiwan</span>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col v-if="innerWidth >= 960" class="col-md-5 text-right my-auto pa-0" style="color:#FFF">
        <v-row class="ma-0" justify-content="flex-end">
          <v-spacer v-if="innerWidth >= 363"></v-spacer>
          <div class="mr-2 pa-1" style="height:34px; width:34px; border-radius: 20px; border: 1px solid #FFF; cursor:pointer" @click="openLink('line')">
            <v-img :src="images.line" width=500 alt="line-icon"></v-img>
          </div>
          <div class="mr-2 pa-1" style="height:34px; width:34px; border-radius: 20px; border: 1px solid #FFF; cursor:pointer" @click="openLink('instagram')">
            <v-img :src="images.instagram" width=500 alt="instagram-icon"></v-img>
          </div>
          <div class="pa-1" style="height:34px; width:34px; border-radius: 20px; border: 1px solid #FFF; cursor:pointer" @click="openLink('youtube')">
            <v-img :src="images.youtube" width=500 alt="youtube-icon"></v-img>
          </div>
          <!-- <v-col class="mx-1 pa-1" style="height:34px; max-width:156px; min-width:156px; border: 1px solid #FFF; cursor:pointer" @click="openLink('instagram')">
            <v-icon color=#FFF>mdi-instagram</v-icon>
            <span class="px-2 pt-1">MyCal_Taiwan</span>
          </v-col>
          <v-col class="pa-1" style="height:34px; max-width:156px; min-width:156px; border: 1px solid #FFF; cursor:pointer" @click="openLink('youtube')">
            <v-icon color=#FFF>mdi-youtube</v-icon>
            <span class="px-2 pt-2">MyCal_Taiwan</span>
          </v-col> -->
        </v-row>
        <!-- <v-row class="ma-0" justify-content="right">
          <v-spacer></v-spacer>
          <p class="text-right pt-1 px-0">{{$t('__copyright')}}</p>
        </v-row> -->
      </v-col>
      <!-- <v-col v-if="innerWidth < 960" class="col-12 text-left my-auto pa-0 pb-2" style="color:#FFF">
        <v-row class="ma-0" justify-content="flex-start">
          <v-col class="mr-1 pa-1" style="height:34px; max-width:34px; min-width:34px; border: 1px solid #FFF; cursor:pointer" @click="openLink('instagram')">
            <v-icon color=#FFF>mdi-instagram</v-icon>
          </v-col>
          <v-col class="mr-1 pa-1" style="height:34px; max-width:34px; min-width:34px; border: 1px solid #FFF; cursor:pointer" @click="openLink('youtube')">
            <v-icon color=#FFF>mdi-youtube</v-icon>
          </v-col>
          <p class="text-left pt-1 px-0">{{$t('__copyright')}}</p>
        </v-row>
      </v-col> -->
    </v-row>
    <v-row class="ma-0 py-2 px-5" style="width:100%; justify-content:center; background: #3D8FC3">
      <span style="color:#FFF; font-size:15px; opacity: 0.85">{{$t('__copyright')}} </span>
      <span class="tabBtn py-0 px-1" style="font-size:15px;font-weight:600" :class="{tab_click:tabIndex==2}" @click="redirectPath('/privacyPage'); tabIndex=2">| {{$t('__privacyPage')}} |</span>
    </v-row>
    <v-row class="ma-0 py-2 px-5" style="width:100%; justify-content:center; background: #2973A0">
      <span style="color:#FFF; font-size:15px; opacity: 0.85">Designed by 
        <a href="https://chihta-software.com/#/homePage" target="_blank" style="color:#FFF; text-decoration:none">chihta software design</a>
      </span>
      <span class="pl-2" style="color:#FFF; font-size:15px; opacity: 0.85">v1.01</span>
    </v-row>
    <LoginPage ref="LoginPage" :onSave="onLoginPage"/>
  </v-footer>
</template>

<script>
import LoginPage from '@/dialogs/LoginPage'
import i18n from '@/plugins/i18n'
export default {
  name: 'MainFooter',
  components: {LoginPage},
  props:['onSave'],
  data: () => ({
    innerWidth: 0,
    tabIndex:0,
    images:{
      youtube: require('@/assets/image/youtube-w.svg'),
      line: require('@/assets/image/line-w.svg'),
      instagram: require('@/assets/image/instagram-w.svg'),
    },
  }),
  mounted(){
    this.calculateWindows()
    this.init()
  },
  watch:{
    "$i18n.locale":function(){
      this.init()
    }
  },
  methods:{
    calculateWindows(){
      this.innerWidth = window.innerWidth
    },
    redirectPath(path){
      this.$router.push(path)
    },
    save(){
      this.onSave()
    },
    init(){
    },
    openLink(info){
      if(info == 'instagram'){
        window.open('https://www.instagram.com')
      }else if(info == 'youtube'){
        window.open('https://www.youtube.com')
      }else if(info == 'line'){
        window.open('https://www.post.gov.tw/post/FileCenter/post_ww2/ad/ad_linkpage/1030415_POST_LINE-all.htm')
      }
    },
    confirmButton(value){
      if(!sessionStorage.getItem('myCalToken')){
        this.$refs.LoginPage.open()
      }else{
        this.redirectPath(value)
      }
    },
    onLoginPage(){
      this.redirectPath(this.path)
    },
  }
}
</script>
<style>
  .tabBtn {
    padding: 2px 10px 2px 0px;
    color: #FFF;
    cursor: pointer;
  }
  .tabBtn:hover {
    color: #174075;
  }
</style>