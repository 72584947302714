<template>
  <div :style="{backgroundImage:`url(${images.background})`}" style="background-position: left bottom; background-repeat: repeat-x">
    <div id="router" class="pt-10" :style="[{'height' : (haveToken) ? 'calc(100vh)' : (innerWidth > 959) ? 'calc(100vh - 103px)' : 'calc(100vh - 103px)'}]">
      <div style="height:100%; margin:0%; justify-content:center"
      :style="[{'margin-right' : (this.displayMode == 0) ? '10%' : '0%'},
      {'margin-left' : (this.displayMode == 0) ? '10%' : '0%'}]">
        <v-row class="ma-0 pa-0">
          <h1 class="text-left mt-2 px-6 styleH4">{{$t('__orderRecord')}}</h1>
        </v-row>
        <v-row class="ma-0 pa-0" style="overflow-x:hidden; overflow-y:hidden">
          <div v-if="information==0" id="listTable" style="height: 600px; border:2px solid #B1B1B1; background-color: #F1F5F8">
            <v-row class="ma-auto container" style="height:100%">
              <div class="d-inline-block">
                <v-img class="mb-8" :src="images.recordImg" :width="(displayMode==0) ? '440': '280'" alt="無資料畫面"></v-img>
                <h2 class="text-center">{{$t('__orderRecordMsg')}}</h2>
              </div>
            </v-row>
          </div>
          <div v-else id="listTable" style="height: 600px">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr class="text-center">
                    <th class="text-center">{{$t('__orderInquiry')}}</th>
                    <th @click="sort('orderNumber')">{{$t('__orderNumber')}}<v-icon small>icon-form_sort_off</v-icon></th>
                    <th @click="sort('orderProgram')">{{$t('__orderProgramy')}}<v-icon small>icon-form_sort_off</v-icon></th>
                    <th @click="sort('memberId')">{{$t('__orderMemberId')}}<v-icon small>icon-form_sort_off</v-icon></th>
                    <th @click="sort('createTime')">{{$t('__orderCreateTime')}}<v-icon small>icon-form_sort_off</v-icon></th>
                    <th @click="sort('paymentMethod')">{{$t('__paymentMethod')}}<v-icon small>icon-form_sort_off</v-icon></th>
                    <th @click="sort('frequency')">{{$t('__pricePaymentfrequency')}}<v-icon small>icon-form_sort_off</v-icon></th>
                    <th @click="sort('paymentStatus')">{{$t('__paymentStatus')}}<v-icon small>icon-form_sort_off</v-icon></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data,index) in datasets" :key="index" id="table_body">
                    <td style="min-width:120px">
                      <v-btn class="ml-1" outlined color="#FFF" style="width:100px; height:36px; border-radius:6px; background:#71ABDE"
                      @click="redirectPath('/contactUs', data.orderNumber)">{{$t('__orderInquiry')}}</v-btn>
                    </td>
                    <td style="min-width:130px"><h4 class="content">{{data.orderNumber}}</h4></td>
                    <td style="min-width:110px"><h4 class="content">{{getTypevFindIndex(storageUpgradeDetailList,data.orderProgram)}}</h4></td>
                    <td style="min-width:120px"><h4 class="content">{{data.memberName}}</h4></td>
                    <td style="min-width:200px"><h4 class="content">{{data.createTime}}</h4></td>
                    <td style="min-width:120px"><h4 class="content">{{getTypevDescription(paymentMethodList,language,data.paymentMethod)}}</h4></td>
                    <td style="min-width:120px"><h4 class="content">{{getTypevDescription(frequencyList,language,data.frequency)}}</h4></td>
                    <td style="min-width:120px"><h4 class="content" :style="{'color' : (data.paymentStatus==2) ? '#F00' : (data.paymentStatus==1) ? '#4880C8' : '#000'}">{{getTypevDescription(paymentStatusList,language,data.paymentStatus)}}</h4></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <v-row v-if="information!=0" class="px-5 py-2" style="justify-content:center">
            <v-col class="col-0 col-sm-0 col-md-2 col-lg-2 pagination-max px-5 mt-2 text-right">
              {{$t('__total')}} {{pagination.totalCount}} {{$t('__total2')}}
            </v-col>
            <v-col class="col-12 col-sm-8 col-md-6 col-lg-3 px-0">
              <v-pagination color="#4880C8" v-model="pagination.page" :length="pagination.totalPage" 
              :total-visible="5"></v-pagination>
            </v-col>
            <v-col class="col-12 col-sm-4 col-md-4 col-lg-2">
              <div class="row ma-0">
                <v-col class="col-5 col-sm-4 pt-3 text-right">{{$t('__go')}}</v-col>
                <v-col class="col-2 col-sm-4 pa-0">
                  <v-text-field outlined dense style="border-radius: 8px" v-model="destPage" @keyup.enter="setDestPage()"></v-text-field>
                </v-col>
                <v-col class="col-5 col-sm-4 pt-3 text-left">{{$t('__page')}}</v-col>
              </div>
            </v-col>
          </v-row>
        </v-row>
      </div>
    </div>
    <div v-if="haveToken" style="position: absolute; top:10px; left:10px;"> 
      <div class="ma-1" style="background:#222; border-radius: 30px; height:36px; width:36px; opacity: 0.7; z-index:5"
      @click="redirectPath('/storageUpgrade')">
        <v-icon class="ma-1" size="28px" color="#FFF">mdi-menu-left</v-icon>
      </div>
      <v-img :src="datasets.mainImageImage" width=500 alt="action_04"></v-img>
    </div>
    <ShowMsg ref="ShowMsg" />
  </div>
</template>

<script>
import ShowMsg from '@/dialogs/ShowMsg'
import i18n from '@/plugins/i18n'

export default {
  name: 'StorageUpgradeRecord',
  components: {ShowMsg},
  mounted(){
    this.language = i18n.locale
    this.getPaymentMethodList()
    this.getFrequencyList()
    this.getPaymentStatusList()
    this.getStorageUpgradeDetail()
    if(this.$route.query.page){
      this.pagination.page = parseInt(this.$route.query.page)
      this.getDataset()
    }else{
      this.getDataset()
    }
  },
  data:() => ({
    select:'',
    keyword:'',
    language:'',
    datasets: [],
    haveToken: false,
    pagination:{page:1,totalPage:10,totalCount:10},
    orderBy:'id',
    direction:'desc',
    destPage:'',
    displayMode:'',
    images:{
      background: require('@/assets/image/background-2000.png'),
      recordImg: require('@/assets/image/recordImg.png'),
    },
    information: 1,

    paymentMethodList: [],
    frequencyList: [],
    paymentStatusList: [],
    storageUpgradeDetail:'',
    storageUpgradeDetailList: [],
  }),
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
    this.$root.$on('language',(value)=>{
      this.language = value
    })
  },
  watch:{
    "pagination.page":function(){
      this.getDataset()
    },
    "language":function(){
      this.changeDatasetSel()
    },
    "languageSel":function(){
      this.getDataset()
    },
    "keyword":function(){
      this.getDataset()
    },
  },
  computed: {},
  methods:{
    close(){
      this.keyword = ''
      this.languageSel = ''
    },
    calculateWindows(){
      if(this.$cookie.get('token')){
        this.haveToken = true
      }
      this.innerWidth = window.innerWidth
      if(window.innerWidth >= 960){
        this.displayMode = 0
      } else {
        this.displayMode = 1
      }
    },
    redirectPath(path, id){
      this.$router.push({path: path, query: {id: this.$Global.encrypt(JSON.stringify(id))}})
    },
    setDestPage(){
      this.pagination.page = parseInt(this.destPage)
    },
    sort(field){
      this.orderBy = field
      this.direction = (this.direction == 'desc') ? 'asc' : 'desc'
      this.getDataset()
    },
    search(){
      (this.pagination.page == 1) ? this.getDataset() : this.pagination.page = 1
    },
    getTypevFindIndex(list, value){
      var index = list.findIndex(function(item){return item.useMonth == value})
      return (index != -1) ? list[index].name : null
    },
    getTypevDescription(list, languageSystem, value){
      var index = list.findIndex(function(item){return item.valuev == value})
      if(languageSystem == 'tw'){ //繁中
        return (index != -1) ? list[index].twName : null
      }
      if(languageSystem == 'cn'){ //簡中
        return (index != -1) ? list[index].cnName : null
      }
      if(languageSystem == 'en'){ //英文
        return (index != -1) ? list[index].enName : null
      }
    },
    changeDatasetSel(){
      let tmp = this.storageUpgradeDetail[this.language + 'Content']
      if(tmp){
        this.storageUpgradeDetailList = JSON.parse(tmp)
      }
    },
    getPaymentMethodList(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/typevByCategory/paymentMethod',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.paymentMethodList = response.data.data
      }).catch(()=>{
      }).finally(()=>{
      })
    },
    getFrequencyList(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/typevByCategory/frequency',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.frequencyList = response.data.data
      }).catch(()=>{
      }).finally(()=>{
      })
    },
    getPaymentStatusList(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/typevByCategory/paymentStatus',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.paymentStatusList = response.data.data
      }).catch(()=>{
      }).finally(()=>{
      })
    },
    getStorageUpgradeDetail(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/StorageUpgradeDetail',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.storageUpgradeDetail = response.data.data[0]
      }).catch(()=>{
      }).finally(()=>{
        self.changeDatasetSel()
      })
    },
    getDataset(){
      var self = this
      var request = {
        method: 'GET',
        url: '/paymentRecordListByMemberId',
        params: {
          'memberId': sessionStorage.getItem('myCalId'),
          'page':self.pagination.page,
          'limit':10,
          'orders':self.orderBy + ':' + self.direction,
          // 'keyword':self.keyword != ''? self.keyword: null, //orderNumber/memberId
          // 'endDate':self.endDate != ''? self.endDate: null
        },
      }
      this.$http(request).then(function(response) {
        if(response.data.data.total){
          self.datasets = response.data.data.data
          self.pagination.totalCount = response.data.data.total
          self.pagination.totalPage = response.data.data.page
        }else{
          self.information = 0
        }
      }).catch(()=>{
      })
    },
  }
}
</script>

<style>
  @media (min-width: 960px) {
    .search-bar-min {
      display: none !important;
    }
  }
  @media (max-width: 959px) {
    .search-bar-max, .pagination-max {
      display: none !important;
    }
  }
</style>