<template>
  <v-row justify="center">
    <v-dialog v-model="active" max-width="500">
      <v-card class="pa-3">
        <div class="close-dialog-btn mt-2" style="border-radius:14px">
          <v-icon size="28px" color="#D8DDE4" @click="close">mdi-close</v-icon>
        </div>
        <v-card-title class="text-h5 my-1" style="font-weight:800">
          {{$t('__announcementTitle')}}
        </v-card-title>
        <h4 class="text-left" style="padding:0px 24px" v-html="data"></h4>
        <v-row class="ma-0 px-4 py-4">
          <v-checkbox color="#71ABDE" class="ma-0 pa-0" style="height:20px" v-model="checkboxbtn">
            <template v-slot:label>
              <span style="color:#000000; font-size:14px">{{$t('__announcementCheckbox')}}</span>
            </template>
          </v-checkbox>
        </v-row>
        <v-row class="my-1" justify="center">
          <v-card-actions>
            <v-btn outlined color="#FFF" style="width:200px; height:46px; border-radius:10px; background:#4880C8"
            @click="save">
              {{$t('__confirm')}}
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name:'ShowAnnouncement',
    // props:['onSave'],
    data () {
      return {
        active: false,
        data: '',
        path: '',
        checkboxbtn: false,
      }
    },
    methods:{
      open(data,path){
        this.active = true
        this.data = data
        this.path = path
      },
      close(){
        this.active = false
      },
      save(){
        if(this.checkboxbtn){
          var currentDate = new Date();
          var expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
          if(this.path == 'coupon'){
            this.$cookie.set('myCouponCookie', expirationDate);
          }else{
            this.$cookie.set('myCookie', expirationDate);
          }
        }
        // this.onSave()
        this.close()
      }
    }
  }
</script>