/* /en/lang.js */
export default {
  __homePage: 'Home',
  __myCal: 'MyCal',
  __aboutMyCal: 'About MyCal',
  __privacyPage: 'Privacy', //Privacy and security
  __storageUpgrade: 'Subscription Plan', //Premium Plans
  __couponExchange: 'Coupon',
  __contactUs: 'Contact Us',
  __languageTw: 'Chinese, Traditional',
  __languageCn: 'Chinese, Simplified',
  __languageEn: 'English',
  __login: 'Login',
  __logout: 'Logout',
  __record: 'Subscription Plan Record',
  __coupon: 'My Rewards',
  __myCouponList: 'My Rewards',

  // footer homePage
  __companyDetail: 'MyCal | Details',
  __companyName: 'Rainbow Apps Intelligence Pte. Ltd',
  __unifiedBusinessNo: 'GUI: 89132638',
  __companyAddress: 'Address: 9 F., No. 20, Guanqian E. Rd., Banqiao Dist.,',
  __companyAddress2: 'New Taipei City 220066, Taiwan (R.O.C.)',
  __companyEmail: 'Contact us: service@rainbowapps.asia',
  __copyright: 'Copyright © 2025 Rainbow Apps Intelligence Pte. Ltd. All rights reserved.',

  // universal
  __languageSetting: 'Language Selection',
  __select:'Select',
  __enter:'Enter',
  __selectFile: 'Select',
  __createTime: 'Time Created',
  __updateTime: 'Time Changed',
  __completeInformation: 'Complete Information',
  __content:'Content',
  __back:'Back',
  __save: 'Save',
  __new: 'New',
  __confirm: 'Confirm',
  __cancel: 'Cancel',
  __settings: 'Settings',
  __keywordSearch: 'Keyword Search',
  __categorySearch: 'Category Search',
  __edit: 'Edit',
  __total: 'Total',
  __total2: 'Item',
  __go: 'go',
  __page: 'page',
  __announcementTitle: 'System Announcement',
  __announcementCheckbox: 'No more reminders today. ',

  // LoginPage
  __memberLogin: 'Member Login',
  __managerUserId: 'Account Number',
  __managerEmail: 'Email',
  __enterUserId: 'Please Enter User ID',
  __enterUserEmail: 'Please Enter Email',
  __managerPassword: 'Password',
  __entermanagerPassword: 'Please enter password',
  __isError: 'Incorrect User ID/Password',

  // aboutMyCal
  __operationBase: 'Operations Base',
  __linkSingapore: 'Singapore',
  __linkSingaporeContent: 'Asia Pacific Business and Market Development Centre',
  __linkTaiwan: 'Taiwan',
  __linkTaiwanContent: 'System Development and Maintenance Centre',
  __linkChina: 'China',
  __linkChinaContent: 'Operation Development Center',
  __linkMalaysia: 'Malaysia',
  __linkMalaysiaContent: 'Market Development Center',
  __linkCambodia: 'Cambodia',
  __linkCambodiaContent: 'Operation Development Center',
  __linkPhilippines: 'Philippines',
  __linkPhilippinesContent: 'Market Development Center',

  // ShowMsg
  __requiredTitle: 'Incomplete information',
  __requiredContent: 'Please fill all required fields.',
  __privacyApplyContent: 'Please confirm the items you want to withdraw.',
  __newTitle: 'Added information',
  __newContent: 'The system has added the data.',
  __revisedTitle: 'Revised data',
  __revisedContent: 'The system has modified the data.',
  __deleteTitle: 'Deleted data',
  __deleteContent: 'The system has deleted the data.',
  __importTitle: 'Imported (information)',
  __importContent: 'The system has imported the information!',
  __sendTitle: 'Sent successfully',
  __sendContent: 'Thank you for your inquiry. We will respond to you by email as soon as possible.',
  __applyTitle: 'Application Successful',
  __applyContent: 'Thank you for your application. It will be processed once payment is received.',
  __couponTitle: 'Redemption successful',
  __couponContent: 'Thank you for your redemption. Use within the validity period.',
  __couponTitle2: 'Redemption successful',
  __couponContent2: 'Thank you for your redemption. Continue to enjoy MyCal.',
  __couponTitle3: 'Redeem failed',
  __couponContent3: 'You have not accumulated thirty days of bonuses, please continue to work hard.',
  __privacyTitle: 'Withdrawal Successful',
  __privacyContent: 'Your personal data has been successfully removed from the system.',
  __loginTitle: 'Login failed',
  __loginContent: 'This account has been deactivated and cannot be logged in.',
  __imageTitle: 'Incorrect Image requirements',
  __imageContent: 'Please ensure image size is less than 50KB, with resolution of W750 x H388 pixels.',
  __imageSizeTitle: 'Image does not meet the requirements',
  __imageSize50Content: 'Please make sure the image is smaller than 50KB.',
  __imageSize80Content: 'Please make sure the image is smaller than 80KB.',
  __applyDietTitle: 'Application successful',
  __applyDietContent: 'This function is for quickly applying for February meals. Repeated applications are invalid. Please use it with caution. ',

  // ShowConfirm
  __couponConfirmTitle: 'Confirm redemption',
  __couponConfirmContent: 'Confirm to proceed with the redemption? Once redeemed, the coupon will be sent to your account email on the 5th of next month (email address cannot be changed). Please check your inbox, as no further notification will be sent.',
  __deleteConfirmTitle: 'Confirm deletion',
  __deleteConfirmContent: 'Are you sure you want to delete this information?',
  __privacyConfirmTitle1: 'Confirm Withdrawal',
  __privacyConfirmContent1: 'Confirm to withdraw? After withdrawal, the system will not use your data for subsequent personal information research related to medical matters. However the account remain accessible via the app within subscription period.',
  __privacyConfirmTitle2: 'Confirm Withdrawal',
  __privacyConfirmContent2: 'Confirm to withdraw? After withdrawal, the system will remove your personal information, meal records, coupon records, etc. Please note: Clicking "Confirm" will disable you from logging in to the system to use any function again.',

  // CouponDialogs
  __enterPassword: 'Coupon Redemption',
  __enterPasswordContent: 'Confirm to redeem the coupon? Clicking "Confirm" will proceed with the redemption, and it cannot be reversed.',
  __enterPasswordError: 'Incorrect redemption password',
  __enterPasswordSuccess: 'Redemption',
  
  // contactUs
  __commonProblem: 'FAQ',
  __contactQuestion: 'Enquiry',
  __contactName: 'Name',
  __contactEmail: 'Email',
  __contactQuestionType: 'Nature of Enquiry',
  __contactQuestionContent: 'Enquiry Details',
  __contactQuestionPictures: 'Attach Picture',
  __sendQuestions: 'Sent',

  // privacyPage
  __privacyConsent: 'Privacy Consent',
  __privacyConsentWithdraw: 'Withdrawal of Consent',
  __privacyQuestions: 'Questions about Privacy Consent, please',
  __privacyWithdraw: 'Wish to withdraw your Privacy Consent, please',
  __privacyWithdrawClick: 'Click here',
  __applicantInformation: 'Applicant\'s Information',
  __memberName: 'Username',
  __memberEmail: 'Email',
  __privacyWithdrawItem1: 'Do not agree to participate in the research related to healthcare with my personal information. My account remains accessible via the APP.',
  __privacyWithdrawItem2: 'Do not agree with the Privacy Consent. To remove personal information and usage history related to the account in the APP.',
  __confirmWithdrawal: 'Confirm Withdrawal',

  // storageUpgrade storageUpgradeApply
  __applicationButton1: 'Activate', //Application for Premium Plan
  __applicationButton2: 'Subscription Plan',
  __memberExpiration: 'Mbr. Exp. Date',
  __recordButton1: 'View Subscription',
  __recordButton2: 'Plan Record',
  __pricePurchasePlan: 'Package plan',
  __priceOrderProgram: 'Purchase Options',
  __priceProgramFee: 'Package Fee',
  __pricePeriod: 'Program Period',
  __priceStartDate: 'Start Date',
  __priceEndDate: 'End Date',
  __pricePaymentMethod: 'Payment Method',
  __pricePaymentfrequency: 'Payment Frequency',
  __priceImmediatePayment: 'Pay Immediately',
  __priceOrderDescription: 'Package Details: The subscription confers membership privileges, including storage of personal calorie/meal selection records.',

  __orderRecord: 'MyCal Subscription Plan Record',
  __orderRecordMsg: 'You don\'t have any Subscription Plan record yet.',
  __orderProgramy: 'Subscription Plan',
  __paymentMethod: 'Payment Method',
  __paymentStatus: 'Payment Status',
  __orderInquiry: 'Inquiry',
  __orderNumber: 'Order Number',
  __orderMemberId: 'User ID',
  __orderCreateTime: 'Time of application',
  __programFee: 'Package Fee',
  __orderUpdateTime: 'Update Time',
  __paymentTime: 'PaymentTime',

  // CouponList couponDetail couponInfo
  __couponHello: 'Hello,',
  __couponHelloMsg1: 'Cumulative Usage',
  __couponHelloMsg2: 'day',
  __showDetail: 'Click to view details',
  __alart1: 'Remember, to redeem My Reward.',
  __alart2: 'Remember, every effort you make brings you closer to redeeming those amazing rewards.',
  __couponUse: 'Use',
  __couponStatus0: 'Redeeming',
  __couponStatus1: 'Sent',
  __redemptionNumber: 'Redemption Number',
  __redemptionProgram: 'Redemption Program',
  __manufacturerName: 'Manufacturer name',
  __productName: 'Product Name',
  __applicationTime: 'Date of Redemption',
  __sendTime: 'Date of Usage',
  __usageInterval: 'Usage Interval',
  __useButton: 'Use Button',
  __remainingStock: 'Remaining stock',
  __btnContinue: 'Keep up the good work',
  __btnRedeem: 'Click to redeem',
  __introductionProduct: 'Product introduction',
  __introductionStore: 'Store introduction',
  __couponInfoBtn1: 'Redemption Mechanism',
  __couponInfoBtn2: 'Usage status',
  __couponInfoBtn3: 'Redemption Record',
  __couponInfoBtn4: 'Quick add February meals (test)',
  __couponList: 'Coupon Status',
  __functionMenu: 'Function Menu',
  __couponAll: 'All',
  __couponClear: 'Clear',
  __calendarDateInfo1: 'Expired',
  __calendarDateInfo2: 'Redeemed',
  __calendarDateInfo3: 'Unredeemed',
  __calendarDateInfo4: 'Today',
}