<template>
  <div :style="{backgroundImage:`url(${images.background})`}" style="background-position: left bottom; background-repeat: repeat-x">
    <div id="router" class="pt-10" :style="[{'height' : (haveToken) ? 'calc(100vh)' : (innerWidth > 1125) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]">
      <v-row class="ma-0" style="justify-content:center"
      :style="[{'padding-right' : (displayMode == 0) ? '10%' : '0%'},
      {'padding-left' : (displayMode == 0) ? '10%' : '0%'}]">
        <v-col class="col-12 col-sm-11 col-md-7">
          <v-carousel show-arrows-on-hover cycle interval="5000" height="auto">
            <v-carousel-item eager v-for="(item,i) in imageCarouselList" :key="i" :src="item" @click="func(i)">
              <v-img eager :src="item"/>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col class="col-11 col-sm-11 col-md-4 ma-3" align="center" style="border-radius: 8px; cursor: pointer; background-repeat:no-repeat; background-size: auto 100%" 
        :style="{backgroundImage:`url(${images.bg})`}" @click="redirectPath('/couponInfo')">
          <div class="pa-4 text-left" style="height:100%; display: flex; flex-direction: column;">
            <h1 class="styleH3" style="font-weight:800; height:20px">{{$t('__couponHello')}} {{memberName}}</h1>
            <h1 class="styleH3" style="font-weight:800">{{$t('__couponHelloMsg1')}}<span class="start" style="font-size:32px">{{dietDays}}</span>/30 {{$t('__couponHelloMsg2')}}</h1>
            <v-row class="ma-0" style="color:#3F3D56;">
              <div style="width: 26px; flex-shrink: 0;">
                <v-icon class="pr-1" size="20" color="red">mdi-bell-circle</v-icon>
              </div>
              <div style="flex: 1;">
                <h4 style="word-break: break-word; white-space: normal;">
                  {{ dietDays >= 30 ? $t('__alart1') : $t('__alart2') }}
                </h4>
              </div>
            </v-row>
            <v-row class="mx-0 my-4" style="justify-content:center">
              <div class="py-2" style="width:65%">
                <v-img :src="images.useDay" width=900 alt="useDay pic"></v-img>
              </div>
            </v-row>
            <v-row  v-if="innerWidth>1224" class="mx-0 my-2" style="justify-content:center">
              <div class="proportionPage" :style="{'background' : (proportion>10) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportionPage" :style="{'background' : (proportion>20) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportionPage" :style="{'background' : (proportion>30) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportionPage" :style="{'background' : (proportion>40) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportionPage" :style="{'background' : (proportion>50) ? '#4880C8' : '#B1B1B1'}"></div>

              <div class="proportionPage" :style="{'background' : (proportion>60) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportionPage" :style="{'background' : (proportion>70) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportionPage" :style="{'background' : (proportion>80) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportionPage" :style="{'background' : (proportion>90) ? '#4880C8' : '#B1B1B1'}"></div>
              <div class="proportionPage" :style="{'background' : (proportion>100) ? '#4880C8' : '#B1B1B1'}"></div>
            </v-row>
            <v-row class="ma-0" style="justify-content:center; height:36px; border-radius: 4px; background:#3F3D56">
              <h3 class="pt-1" style="color:#FFF">{{$t('__showDetail')}}</h3>
              <v-icon class="pl-1" size="20" style="color:#FFF">mdi-arrow-right-circle</v-icon>
            </v-row>
          </div>
        </v-col>
        
        <v-row v-if="datasetSwitch == true" class="ma-0 cardWrap" style="width:100%">
          <v-checkbox v-for="(dataA, indexA) in productTagList" :key = indexA :value = "dataA.valuev" v-model="productTagSel"
            class="ma-0 pa-1" color="#71ABDE">
            <template v-slot:label>
              <span class="pt14" style="color:#000000">{{dataA.content}}</span>
            </template>
          </v-checkbox>
          <v-btn v-if="displayMode == 0" class="ml-1" outlined color="#FFF" style="width:50px; height:36px; border-radius:6px; background:#71ABDE"
          @click="checkboxSelect()">{{$t('__couponAll')}}</v-btn>
          <v-btn class="ml-1" outlined color="#FFF" style="width:50px; height:36px; border-radius:6px; background:#999"
          @click="checkboxClear()">{{$t('__couponClear')}}</v-btn>
        </v-row>
        <v-row v-if="datasetSwitch == true" class="ma-0 cardWrap">
          <div class="ma-0" v-for="(data,index) in couponOptionList" :key="index" style="min-width:160px; max-width:20%">
            <v-card class="ma-1 pa-0" style="position:relative" @click="redirectPathDetail('/couponDetail', data.id)">
              <v-row class="ma-0 px-3 py-0 text-left" style="background:#404040">
                <v-icon class="ma-1" large color="#FFF" style="font-size:24px">mdi-gift-open-outline</v-icon>
                <h3 class="ma-0 pa-0 py-2 mt-1" style="color:#FFF">{{data.productFeature}}</h3>
              </v-row>
              <v-row class="ma-0 pa-1 pb-2" style="justify-content:center"
              :style="{'opacity' : (data.totalAmount == data.usedAmount) ? '40%' : '100%'}">
                <v-row style="margin:10px 10px; width:100%; justify-content:center">
                  <div style="width:150px">
                    <v-img :src="data.mainImageImage" eager></v-img>
                  </div>
                </v-row>
                  <div class="mt-1" style="min-width:100px">
                    <h3 class="ma-0 pa-0 multilineEllipsis" style="color:#848484">{{data.productName}}</h3>
                    <!-- <h6 class="ma-0 pa-0 multilineEllipsis" style="color:#ED4346">{{data.availablePeriodStart}} - {{data.availablePeriodEnd}}</h6> -->
                  </div>
              </v-row>
              <v-img v-if="data.totalAmount === data.usedAmount" class="ma-0 pa-10"
                :src="images.finish" alt="finish pic"
                style="position:absolute; bottom:10px; right:10px; width:80px; height:80px; transform: rotate(-12deg)"
              ></v-img>
            </v-card>
          </div>
        </v-row>
      </v-row>
    <ShowAnnouncement ref="ShowAnnouncement"/>
    </div>
  </div>
</template>

<script>
import ShowAnnouncement from '@/dialogs/ShowAnnouncement'
import i18n from '@/plugins/i18n'
export default {
  name: 'CouponPage',
  props: {},
  components: {ShowAnnouncement},
  data:() => ({
    datasets:[],
    haveToken: false,
    datasetSwitch: false,
    couponOptionList:[],
    couponAvailableStatus: true,
    couponAvailableList:[],
    language:'',
    scrollNum: 0, //滾動距離
    isTop: false, //是否顯示回到頂部按鈕
    innerWidth: 0,
    images:{
      background: require('@/assets/image/background-2000.png'),
      marathon: require('@/assets/image/marathon.png'),
      useDay: require('@/assets/image/useDay.png'),
      finish: require('@/assets/image/finish.png'),
      bg: require('@/assets/image/bg.png'),
    },
    imageItemList: ['','','','',''],
    imageCarouselList: [],
    productTagList: [],
    productTagSel: [],
    displayMode:'',
    dietDays:'',
    proportion: 0,
    memberName:'',
    memberId:'',
    memberStatus:'',
  }),
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
    this.$root.$on('language',(value)=>{
      this.language = value
      this.changeListSel(this.productTagList)
    })
    this.$root.$on('setSessionStorage',(value)=>{
      if(value){
        this.getDietDays()
      }
    })
  },
  mounted() {
    this.language = i18n.locale
    this.memberName = sessionStorage.getItem('myCalName')
    this.memberId = sessionStorage.getItem('myCalId')
    this.memberStatus = sessionStorage.getItem('myCalMemberStatus')
    this.getExpirationList()
    this.getDataset()
    this.getDatasetSwitch()
    this.getDietDays()
    // setTimeout(() => { this.getDietDays() }, 500); //delay 0.1s
    this.$Global.getProductTagList().then(res=>{ this.productTagList = res, this.changeListSel(this.productTagList)})
  },
  watch:{
    "language":function(){
      this.changeLanguageSel()
    },
    "productTagSel":function(){
      this.getCouponOptionList()
    },
  },
  methods:{
    calculateWindows(){
      if(this.$cookie.get('token')){
        this.haveToken = true
      }
      this.innerWidth = window.innerWidth
      if(window.innerWidth >= 960){
        this.displayMode = 0
      } else {
        this.displayMode = 1
      }
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    redirectPathDetail(path,id){
      this.$router.push({path: path, query: {
        id: id,
      }}).catch(()=>{})
    },
    func(i){
      for(var j=0; j<this.couponAvailableList.length; j++){
        if(this.datasets[i].note1  == this.couponAvailableList[j]){
          this.redirectPathDetail('/couponDetail', this.datasets[i].note1)
          return
        }
      }
    },
    checkboxSelect(){
      var tmp = []
      for(var i=0; i<this.productTagList.length; i++){
        // this.$set(tmp, this.productTagList[i].valuev)
        tmp.push(this.productTagList[i].valuev)
      }
      this.productTagSel = tmp
    },
    checkboxClear(){
      this.productTagSel = []
    },
    changeListSel(List){
      for(var i=0; i<List.length; i++){
        if(this.language=='tw'){
          this.$set(List[i], 'content', List[i].twName)
        }else if(this.language=='cn'){
          this.$set(List[i], 'content', List[i].cnName)
        }else if(this.language=='en'){
          this.$set(List[i], 'content', List[i].enName)
        }
      }
    },
    changeLanguageSel(){
      for(var j=0; j<this.datasets.length; j++){
        this.$set(this.datasets[j], 'content', this.datasets[j][this.language + 'Content'])
        this.$set(this.datasets[j], 'contentImage', this.datasets[j][this.language + 'ContentImage'])
      }

      //update imageItemList
      for(var i=0; i<this.datasets.length; i++){
        this.imageItemList[i] = this.datasets[i][this.language + 'ContentImage']
      }

      //update imageCarouselList
      this.updateImageCarouselList()

      //reset fileItem
      for(var i=0; i<5; i++){
        this['fileItem'+i] = null
      }
    },
    updateImageCarouselList(){
      this.imageCarouselList = []
      for(var i=0; i<this.datasets.length; i++){
        if(this.datasets[i][this.language + 'Content'] && this.datasets[i][this.language + 'Content'] != ''){
          this.imageCarouselList.push(this.$Global.getUrlImage() + this.datasets[i][this.language + 'Content'])
        }
      }
    },
    getExpirationList(){
      var myCalExpiration
      var self = this
      var request = {
        method: 'POST',
        url: '/mycalMemberExpiration',
        data: this.Qs.stringify({
          memberId: sessionStorage.getItem('myCalId'),
        }),
      }
      this.$http(request).then(function(response) {
        myCalExpiration = response.data.msg
      }).catch(()=>{
        self.$refs.ShowMsg.open(0,'系統錯誤','請聯繫系統管理人員，編號為Cot-8121')
      }).finally(()=>{
        if (new Date()<new Date(this.$cookie.get('myCouponCookie'))) {
          console.log('今日不再提示優惠券兌換期限')
        }else{
          if (this.memberStatus == '1'){
            this.$refs.ShowAnnouncement.open('您的帳號已於' + myCalExpiration + '過期，請於到期後一個月內將優惠券兌換完畢，欲保留帳號請盡速加值。','coupon')
          }
        }
      })
    },
    getDataset(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/CouponCarousel',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.datasets = response.data.data
      }).catch(()=>{
      }).finally(()=>{
        for(var i=0; i<self.datasets.length; i++){
          let tmp1 = self.datasets[i]
          if(tmp1.twContent){
            self.$set(tmp1, 'twContentImage', this.$Global.getUrlImage() + tmp1.twContent)
          }
          if(tmp1.cnContent){
            self.$set(tmp1, 'cnContentImage', this.$Global.getUrlImage() + tmp1.cnContent)
          }
          if(tmp1.enContent){
            self.$set(tmp1, 'enContentImage', this.$Global.getUrlImage() + tmp1.enContent)
          }
        }
        self.changeLanguageSel()
      })
    },
    getDatasetSwitch(){
      var self = this
      let userData
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/ExchangeSwitch',
        params: {},
      }
      this.$http(request).then(function(response) {
        userData = response.data.data[0]
      }).catch(()=>{
      }).finally(()=>{
        if(userData.twContent == 'true'){
          this.datasetSwitch = true
          this.getCouponOptionList()
        }else{
          this.datasetSwitch = false
        }
      })
    },
    getDietDays(){
      this.memberName = sessionStorage.getItem('myCalName')
      this.memberId = sessionStorage.getItem('myCalId')
      var self = this
      var request = {
        method: 'GET',
        url: '/common/totalDietDays',
        params: {
          memberId: self.memberId,
        },
      }
      this.$http(request).then(function(response) {
        self.dietDays = response.data
      }).catch(()=>{
      }).finally(()=>{
        self.proportion =  self.dietDays / 30 * 100
        if(self.dietDays > 30){
          sessionStorage.setItem('totalDietDays', 'true')
        }else{
          sessionStorage.setItem('totalDietDays', 'false')
        }
      })
    },
    getCouponOptionList(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/couponOptionListAvailableNew',
        params: {
          'productTags':self.productTagSel != []? self.productTagSel.toString() : null
        },
      }
      this.$http(request).then(function(response) {
        self.couponOptionList = response.data.data
      }).catch(()=>{
      }).finally(()=>{
        for(var i=0; i<self.couponOptionList.length; i++){
          if(self.couponAvailableStatus){
            self.couponAvailableList.push(self.couponOptionList[i].id)
          }
          let tmp1 = self.couponOptionList[i]
          self.$set(tmp1, 'mainImageImage', this.$Global.getUrlImage() + tmp1.mainImage)
          self.$set(tmp1, 'productImage1Image', this.$Global.getUrlImage() + tmp1.productImage1)
          self.$set(tmp1, 'productImage2Image', this.$Global.getUrlImage() + tmp1.productImage2)
          self.$set(tmp1, 'productImage3Image', this.$Global.getUrlImage() + tmp1.productImage3)
          self.$set(tmp1, 'productImage4Image', this.$Global.getUrlImage() + tmp1.productImage4)
        }
        self.couponAvailableStatus = !self.couponAvailableStatus
      })
    },
  }
}
</script>

