<script>
import axios from 'axios'
import CryptoJS from "crypto-js"

function copy(value){
  if(value){
    return JSON.parse(JSON.stringify(value))
  } else {
    return null
  }
}

function encrypt(word, keyStr, ivStr) {
  const KEY = CryptoJS.enc.Utf8.parse("78afc8512559b62f");
  const IV = CryptoJS.enc.Utf8.parse("1234567890123456");
  
  let key = KEY
  let iv = IV

  if (keyStr) {
    key = CryptoJS.enc.Utf8.parse(keyStr);
    iv = CryptoJS.enc.Utf8.parse(ivStr);
  }

  let srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  // console.log("-=-=-=-", encrypted.ciphertext)
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}

function decrypt(word, keyStr, ivStr) {
  const KEY = CryptoJS.enc.Utf8.parse("78afc8512559b62f");
  const IV = CryptoJS.enc.Utf8.parse("1234567890123456");
  let key = KEY
  let iv = IV

  if (keyStr) {
    key = CryptoJS.enc.Utf8.parse(keyStr);
    iv = CryptoJS.enc.Utf8.parse(ivStr);
  }

  let base64 = CryptoJS.enc.Base64.parse(word);
  let src = CryptoJS.enc.Base64.stringify(base64);

  var decrypt = CryptoJS.AES.decrypt(src, key, {
    iv: iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });

  var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

function toCDB(value){
  var tmp = ""; 
  for(var i=0;i<value.length;i++){ 
    if (value.charCodeAt(i) == 12288){
      tmp += String.fromCharCode(value.charCodeAt(i)-12256);
      continue;
    }
    if(value.charCodeAt(i) > 65280 && value.charCodeAt(i) < 65375){ 
      tmp += String.fromCharCode(value.charCodeAt(i)-65248); 
    } 
    else{ 
      tmp += String.fromCharCode(value.charCodeAt(i)); 
    } 
  }
  return tmp 
}

function jsonConcat(o1, o2){
  for (var key in o2) {
    o1[key] = o2[key];
  }
  return o1;
}

function jsonAdjust(inData){
  /** NG **/
  // let tmp1 = '[{"id":"","questionBackId":"","detailId":"","answerId":"1","answerContent":"O(1)"},{"id":"","questionBackId":"","detailId":"","answerId":"2","answerContent":"O(n)"},{"id":"","questionBackId":"","detailId":"","answerId":"3","answerContent":"O(nlogn)"},{"id":"","questionBackId":"","detailId":"","answerId":"4","answerContent":"<img src="pic/ch3-73d.gif">"}]'
  // console.log(tmp1)
  // console.log(JSON.parse(tmp1))

  /** OK **/
  // let tmp2 = '[{"id":"","questionBackId":"","detailId":"","answerId":"1","answerContent":"O(1)"},{"id":"","questionBackId":"","detailId":"","answerId":"2","answerContent":"O(n)"},{"id":"","questionBackId":"","detailId":"","answerId":"3","answerContent":"O(nlogn)"},{"id":"","questionBackId":"","detailId":"","answerId":"4","answerContent":"<img src=\\"pic/ch3-73d.gif\\">"}]'
  // console.log(tmp2)
  // console.log(JSON.parse(tmp2))

  /** process from tmp1 to tmp2 **/
  /** from "answerContent":"<img src="pic/ch3-73d.gif">"
      to   "answerContent":"<img src=\"pic/ch3-73d.gif\">" **/

  let res = ''
  let tmp = inData
  let times = 0
  while(times < 100){ //at most 100 error
    let count = 0
    let isError = false
    let idxError = 0
    for(var i=0; i<tmp.length; i++){
      if(tmp[i] == '"' && (i-1 >= 0 && tmp[i-1] != '\\' || (i-1 >= 0 && tmp[i-1] == '\\' && i-2 >= 0 && tmp[i-2] == '\\'))){
        count++
      }
      if(count % 2 == 0 && tmp[i] == '"'){
        if((i-1 >= 0 && tmp[i-1] != '\\'  || (i-1 >= 0 && tmp[i-1] == '\\' && i-2 >= 0 && tmp[i-2] == '\\')) &&
          i+1 <= tmp.length-1 && tmp[i+1] != ',' && tmp[i+1] != ':' && tmp[i+1] != '"' && tmp[i+1] != '}'){
          // console.log('tmp[i-1]: ' + tmp[i-1])
          // console.log('tmp[i]: ' + tmp[i])
          // console.log('tmp[i+1]: ' + tmp[i+1])
          isError = true
          idxError = i
          break
        }
      }
    }
    if(isError){
      // console.log('isError')
      // console.log('[ori]tmp: ' + tmp)
      tmp = tmp.slice(0,idxError) + '\\' + tmp.slice(idxError, tmp.length)
      // console.log('[new]tmp: ' + tmp)
    }else{
      res = tmp
      break
    }
  }

  if(inData != res){
    console.log('[jsonAdjust]inData: ' + inData)
    console.log('[jsonAdjust]res:    ' + res)
    // console.log('[jsonAdjust]JSON.parse(res): ')
    // console.log(JSON.parse(res))
  }

  return res
}

function checkForm(list, requiredField){
  let result = true
  for(var key of requiredField){
    if(!list[key]){
      result = false
    }
  }
  return result
}

function keyupInt(value, max=null){
  value = value.replace(/\D/g,'') //�u��ƤJ�Ʀr
  value = value.replace(/^[0]+/,'') //�h���}�Y�h�l��0
  if(!value || value == ''){
    value = 0
  }

  if(max){
    value = parseInt(value)
    max = parseInt(max)
    if(value > max){
      value = max
    }
    value = value.toString()
  }

  return value
}

function updateFormula(id,value){
  setTimeout(() => {
    let mf = document.getElementById(id)
    if(mf){
      // mf.letterShapeStyle = 'tex'
      mf.setValue(value,{mode: 'math'})
    }
  }, 0);
}

function getUrlImage(){
  let ipAddr = sessionStorage.getItem('ipAddr')
  if(ipAddr){
    return 'https://' + ipAddr + '/file/common/previewImage?name='
  }else{
    return 'https://rainbowapps.asia/api/file/common/previewImage?name='
  }
}

function getTypevDescription(list, languageSystem, value){
  var index = list.findIndex(function(item){return item.valuev == value})
  if(languageSystem == 'tw'){ //繁中
    return (index != -1) ? list[index].twName : null
  }
  if(languageSystem == 'cn'){ //簡中
    return (index != -1) ? list[index].cnName : null
  }
  if(languageSystem == 'en'){ //英文
    return (index != -1) ? list[index].enName : null
  }
  // var index = list.findIndex(function(item){return item.valuev == value})
  // return (index != -1) ? list[index].title : null
}

function onload2promise(obj){
  return new Promise((resolve, reject) => {
    obj.onload = () => resolve(obj);
    obj.onerror = reject;
  });
}

async function getImageWidth(src){
  let img = new Image();
  let imgpromise = onload2promise(img);
  img.src = src;
  await imgpromise;
  return img.width;
}

async function imageCheck(file, imgSrc, maxSize=null, maxWidth=null, maxHeight=null){
  let img = new Image()
  let imgpromise = onload2promise(img)
  img.src = imgSrc
  await imgpromise

  // console.log('img.width: ' + img.width)
  // console.log('img.height: ' + img.height)
  // console.log(file)
  // console.log('file name: ' + file.name)
  // console.log('file size: ' + file.size)

  if(
    (!maxSize || file.size <= maxSize * 1024) &&
    (!maxWidth || img.width <= maxWidth) &&
    (!maxHeight || img.height <= maxHeight)
  ){
    console.log('[imageCheck]true')
    return true
  }else{
    console.log('[imageCheck]false')
    return false
  }
}

async function getExtendedProgramList(){
  let res = []
  var request = {
    method: 'GET',
    url: '/common/typevByCategory/extendedProgram',
    params: {},
  }
  await axios(request)
    .then(function(response) {
      res = response.data.data
    }).catch(()=>{
    }).finally(()=>{
    })
  return res
}

async function getProductTagList(){
  let res = []
  var request = {
    method: 'GET',
    url: '/common/typevByCategory/productTag',
    params: {},
  }
  await axios(request)
    .then(function(response) {
      res = response.data.data
    }).catch(()=>{
    }).finally(()=>{
    })
  return res
}

export default
{
  copy,
  encrypt,
  decrypt,
  toCDB,
  jsonConcat,
  jsonAdjust,
  checkForm,
  keyupInt,
  updateFormula,
  getUrlImage,
  getTypevDescription,
  getImageWidth,
  imageCheck,
  getExtendedProgramList,
  getProductTagList,
}
</script>