<template>
  <div>
  <!-- <div :style="{backgroundImage:`url(${images.homePage})`}" style="background-position: center bottom"> -->
    <div id="router" class="pt-10" :style="[{'height' : (innerWidth > 1125) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]" style="position: relative; z-index: 1">
      <div v-if="innerWidth >= 960">
        <div style="width: 120px; position:absolute; top: 40px ;left: -50px; z-index: 1">
          <v-img :src="images.vegetables1" style="animation: oxxo 2s linear infinite"></v-img>
        </div>
        <div style="width: 120px; position:absolute; bottom: 100px ;left: -30px; z-index: 0">
          <v-img :src="images.vegetables2" style="animation: oxxo 2s linear infinite"></v-img>
        </div>
        <div style="width: 120px; position:absolute; top: 20px ;right: -30px; z-index: 1">
          <v-img :src="images.vegetables3" style="animation: oxxo 2s linear infinite"></v-img>
        </div>
        <!-- <div style="width: 50px; position:absolute; bottom: 120px ;right: 5%; z-index: 1">
          <v-img :src="images.vegetables4" style="animation: oxxo 2s linear infinite"></v-img>
        </div> -->
        <div style="width: 95px; position:absolute; bottom: 80px ;right: -20px; z-index: 0">
          <v-img :src="images.vegetables5" style="animation: oxxo 2s linear infinite"></v-img>
        </div>
        <div class="ma-0" style="background:#FBFBFB; z-index: -2">
          <v-row class="ma-0" style="width:100%; justify-content:center">
            <div class="pa-0" style="width:80%; position: relative">
              <v-img v-if="language != 'en'" :src="images.vegetablesV1" eager></v-img>
              <v-img v-if="language == 'en'" :src="images.vegetablesV2" eager></v-img>
              <!-- <v-btn class="pa-4 styleBtn" outlined style="color:#FFF; background:#4880C8; position:absolute; bottom: 100px ;right: 80px" @click="redirectPath('/homePageDetail')">
                <h5 class="center">{{$t('__companyDetail')}}</h5>
              </v-btn> -->
            </div>
          </v-row>
        </div>
      </div>
      <div v-if="innerWidth < 960">
        <div class="ma-0" style="height:100%; background:#FBFBFB; z-index: -2">
          <v-row class="ma-0" style="width:100%; justify-content:center">
            <div class="pa-0" style="width:90%; max-width:600px; position: relative">
              <v-img v-if="language != 'en'" :src="images.vegetablesV3" eager></v-img>
              <v-img v-if="language == 'en'" :src="images.vegetablesV4" eager></v-img>
              <!-- <v-btn v-if="innerWidth < 500" class="pa-4 styleBtn" outlined style="color:#FFF; background:#4880C8; position:absolute; bottom: 80px ;left: 2px" @click="redirectPath('/homePageDetail')">
                <h6 class="center">{{$t('__companyDetail')}}</h6>
              </v-btn>
              <v-btn v-if="innerWidth >= 500" class="pa-4 styleBtn" outlined style="color:#FFF; background:#4880C8; position:absolute; bottom: 140px ;left: 2px" @click="redirectPath('/homePageDetail')">
                <h5 class="center">{{$t('__companyDetail')}}</h5>
              </v-btn> -->
            </div>
          </v-row>
        </div>
      </div>
      <v-row v-if="datasets.length != 0" class="ma-0" style="justify-content:center">
        <div style="width: 120px; position:absolute; top: 40px ;left: -50px; z-index: -1">
          <v-img :src="images.vegetables1" style="animation: oxxo 2s linear infinite"></v-img>
        </div>
        <div style="width: 90px; position:absolute; bottom: -450px ;left: -30px; z-index: -1">
          <v-img :src="images.vegetables3" style="animation: oxxo 2s linear infinite"></v-img>
        </div>
        <div style="width: 120px; position:absolute; top: 20px ;right: -30px; z-index: -1">
          <v-img :src="images.vegetables3" style="animation: oxxo 2s linear infinite"></v-img>
        </div>
        <div style="width: 50px; position:absolute; bottom: -350px ;right: 3%; z-index: -1">
          <v-img :src="images.vegetables4" style="animation: oxxo 2s linear infinite"></v-img>
        </div>
        <div style="width: 90px; position:absolute; bottom: -850px ;right: -20px; z-index: -1">
          <v-img :src="images.vegetables2" style="animation: oxxo 2s linear infinite"></v-img>
        </div>
        <v-row class="ma-0 pa-0 col-11 col-md-7" style="justify-content:center">
          <div v-if="imageItemList[0]" style="width:100%">
            <v-img :src="imageItemList[0]" eager></v-img>
          </div>
        </v-row>
        <v-row class="pa-0 col-11 col-md-7" style="margin: 2% 0%; justify-content:center; background:#FFF">
          <v-col cols="4" class="ma-0 pa-0" style="display:flex; align-items:center; flex-direction:column">
            <div v-if="imageItemList[1]" class="pl-2" style="width: 65%">
              <v-img :src="imageItemList[1]" eager></v-img>
            </div>
            <h1 class="styleH4 colorFull">{{datasets[2].content}}</h1>
            <h2 class="styleT4">{{datasets[3].content}}</h2>
          </v-col>
          <v-col cols="4" class="ma-0 pa-0" style="display:flex; align-items:center; flex-direction:column">
            <div v-if="imageItemList[2]" class="pl-2" style="width: 65%">
              <v-img :src="imageItemList[2]" eager></v-img>
            </div>
            <h1 class="styleH4 colorFull">{{datasets[5].content}}</h1>
            <h2 class="styleT4">{{datasets[6].content}}</h2>
          </v-col>
          <v-col cols="4" class="ma-0 pa-0" style="display:flex; align-items:center; flex-direction:column">
            <div v-if="imageItemList[3]" class="pl-2" style="width: 65%">
              <v-img :src="imageItemList[3]" eager></v-img>
            </div>
            <h1 class="styleH4 colorFull">{{datasets[8].content}}</h1>
            <h2 class="styleT4">{{datasets[9].content}}</h2>
          </v-col>
        </v-row>
        <v-row class="col-11 col-md-7" style="margin: 0% 0%; padding: 2% 0%; justify-content:center; border-top: 4px solid #0C80BD">
          <v-col class="ma-0 pa-0 col-11 col-md-8">
            <youtube player-width="100%" :video-id="videoId" eager></youtube>
          </v-col>
          <v-col class="ma-0 pa-0 pl-8 col-11 col-md-4 text-left" align-self="center">
            <v-img v-if="language != 'en'" class="finger my-2" :src="images.logoTw" width=180 alt="MyCal-LOGO-TW"
            :style="{'margin-left' : (innerWidth >= 503) ? '0px' : '0px'}"></v-img>
            <v-img v-if="language == 'en'" class="finger my-2" :src="images.logoEn" width=180 alt="MyCal-LOGO-TW"
            :style="{'margin-left' : (innerWidth >= 503) ? '0px' : '0px'}"></v-img>
            <!-- <h1 class="styleH4">{{datasets[11].content}}</h1> -->
            <h2 class="styleT4">{{datasets[12].content}}</h2>
            <v-row class="ma-0 pt-2" style="justify-content:space-around">
              <div class="pa-0" style="width:48%; cursor:pointer" @click="openLink('googlePlay')">
                <v-img :src="images.googlePlay" alt="googlePlay_link" eager></v-img>
              </div>
              <div class="pa-0" style="width:48%; cursor:pointer" @click="openLink('appStore')">
                <v-img :src="images.appStore" alt="appStore_link" eager></v-img>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
      <div style="width: 100%">
        <MainFooter ref="MainFooter" />
      </div>
    </div>
    <div class="goTop iconfont ml-gotop" :class="isTop ? 'goTopAfter' : ''" @click="goTop()">
      <h1 class="my-3 mx-2 contentTitle" style="font-weight: 700; color: #FFF; font-size: 20px">TOP</h1>
    </div>
  </div>
</template>

<script>
import MainFooter from '@/components/MainFooter.vue'
import i18n from '@/plugins/i18n'
export default {
  name: 'HomePage',
  components: {MainFooter},
  props: {},
  data:() => ({
    language:'tw',
    scrollNum: 0, //滾動距離
    isTop: false, //是否顯示回到頂部按鈕
    innerWidth: 0,
    keyword:'',
    datasets: [],
    images:{
      logoTw: require('@/assets/image/logo_Tw.png'),
      logoEn: require('@/assets/image/logo_En.png'),
      googlePlay: require('@/assets/image/googlePlay.png'),
      appStore: require('@/assets/image/appStore.png'),
      background: require('@/assets/image/background-2000.png'),
      homePage: require('@/assets/image/homePage.png'),
      vegetables: require('@/assets/image/vegetables.gif'),
      vegetablesV1: require('@/assets/image/vegetables-v1.gif'),
      vegetablesV2: require('@/assets/image/vegetables-v2.gif'),
      vegetablesV3: require('@/assets/image/vegetables-v3.gif'),
      vegetablesV4: require('@/assets/image/vegetables-v4.gif'),
      vegetables1: require('@/assets/image/vegetables1.png'),
      vegetables2: require('@/assets/image/vegetables2.png'),
      vegetables3: require('@/assets/image/vegetables3.png'),
      vegetables4: require('@/assets/image/vegetables4.png'),
      vegetables5: require('@/assets/image/vegetables5.png'),
    },
    videoId:'',
    imageItemList: ['','','',''],
  }),
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
    this.$root.$on('language',(value)=>{
      this.language = value
      this.changeLanguageSel()
    })
  },
  mounted() {
    this.language = i18n.locale
    window.addEventListener("scroll", () => {
      let top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      this.scrollNum = top;
      if (top >= 20) {
        this.isTop = true;
      } else {
        this.isTop = false;
      }
    });
    this.getDataset()
  },
  methods:{
    calculateWindows(){
      this.innerWidth = window.innerWidth
    },
    redirectPath(path){
      this.$router.push({path: path}).catch(()=>{})
    },
    changeLanguageSel(){
      for(var j=0; j<this.datasets.length; j++){
        this.$set(this.datasets[j], 'content', this.datasets[j][this.language + 'Content'])
        this.$set(this.datasets[j], 'contentImage', this.datasets[j][this.language + 'ContentImage'])
        if(j == 10){
          this.videoId = this.$youtube.getIdFromURL(this.datasets[10].content)
        }
      }
        
      //update imageItemList
      if(this.datasets.length > 0){
        this.imageItemList[0] = this.datasets[0][this.language + 'ContentImage']
        this.imageItemList[1] = this.datasets[1][this.language + 'ContentImage']
        this.imageItemList[2] = this.datasets[4][this.language + 'ContentImage']
        this.imageItemList[3] = this.datasets[7][this.language + 'ContentImage']
      }
    },
    getDataset(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/AppIntroduction',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.datasets = []
        self.datasets = response.data.data
      }).catch(()=>{
      }).finally(()=>{
        for(var i=0; i<self.datasets.length; i++){
          let tmp1 = self.datasets[i]
          if(tmp1.twContent){
            self.$set(tmp1, 'twContentImage', this.$Global.getUrlImage() + tmp1.twContent)
          }
          if(tmp1.cnContent){
            self.$set(tmp1, 'cnContentImage', this.$Global.getUrlImage() + tmp1.cnContent)
          }
          if(tmp1.enContent){
            self.$set(tmp1, 'enContentImage', this.$Global.getUrlImage() + tmp1.enContent)
          }
        }
        self.changeLanguageSel()
      })
    },
    openLink(info){
      if(info == 'instagram'){
        window.open('https://www.instagram.com')
      }else if(info == 'youtube'){
        window.open('https://www.youtube.com')
      }else if(info == 'googlePlay'){
        window.open('https://play.google.com')
      }else if(info == 'appStore'){
        window.open('https://www.apple.com/tw/app-store/')
      }
    },
    goTop(){
      document.documentElement.scrollTop = 0;
    },
  }
}
</script>

