<template>
  <div :style="{backgroundImage:`url(${images.background})`}" style="background-position: left bottom; background-repeat: repeat-x">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true">
    </loading>
    <div id="router" class="pt-10" :style="[{'height' : (haveToken) ? 'calc(100vh)' : (innerWidth > 1125) ? 'calc(100vh - 103px)' : 'calc(100vh - 127px)'}]">
      <v-row justify-content="center" :style="[{'margin' : (innerWidth > 959) ? '1% 8%' : '0% 0%'}]">
        <v-col class="col-12 col-md-6 px-0" align="center">
          <p class="styleH4">{{$t('__commonProblem')}}</p>
          <div id="box" class="setting">
            <v-expansion-panels accordion v-model="expandedPanel" style="z-index: initial">
              <v-expansion-panel v-for="(menu,i) in commonProblemList" :key="i">
                <v-expansion-panel-header @click="selectMenu(menu)"
                  :class="{ selectedMenu: menu.chapterName==currentMenuName }">
                  <div class="col-10 ma-0 px-0 py-2 weight600">
                    {{menu.chapterName}}
                  </div>
                  <template v-slot:actions>
                    <v-icon :color="(menu.name==currentMenuName)?'#FFF':'#000'">$expand</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="my-3 pa-0 ma-0" v-for="(submenu, j) in menu.section" :key="j">
                    <v-col cols="10" class="ma-0 pa-0 text-left weight600">
                      <span class="ma-0 pa-0 pt14">{{submenu.sectionName}}</span>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>

        <v-col class="col-12 col-md-6 px-0" align="center">
          <p class="styleH4">{{$t('__contactUs')}}</p>
          <div id="box">
            <v-row class="pa-3 pb-8">
              <v-icon class="ma-0 pa-0" size="30" style="color:#4880C8">mdi-menu-right</v-icon>
              <h4 class="DarkBlue pt-1">{{$t('__contactQuestion')}}</h4>
            </v-row>
            <v-col>
              <v-text-field v-if="infoGet" class="text_field" dense readonly background-color="#F4F8F9" outlined 
                :label="$t('__memberName')" v-model="datasets.memberName">
              </v-text-field>
              <v-text-field v-else class="text_field" dense background-color="white" outlined 
                :label="$t('__memberName')" v-model="datasets.memberName">
              </v-text-field>

              <v-text-field v-if="orderNumber" class="text_field" dense readonly background-color="#F4F8F9" outlined 
                :label="$t('__contactEmail')" v-model="datasets.email">
              </v-text-field>
              <v-text-field v-else class="text_field" dense background-color="white" outlined 
                :label="$t('__contactEmail')" v-model="datasets.email">
              </v-text-field>

              <v-select v-if="orderNumber" class="text_field" dense readonly background-color="#F4F8F9" outlined 
                :items="contactTypeList" :item-text="(language=='cn') ? 'cnName' : (language=='en') ? 'enName' : 'twName'" item-value="valuev"
                :label="$t('__contactQuestionType')" v-model="datasets.contactType">
              </v-select>
              <v-select v-else class="text_field" dense background-color="white" outlined 
                :items="contactTypeList" :item-text="(language=='cn') ? 'cnName' : (language=='en') ? 'enName' : 'twName'" item-value="valuev"
                :label="$t('__contactQuestionType')" v-model="datasets.contactType">
              </v-select>
              
              <v-textarea class="pt14 pa-0" :rows="10" outlined required dense style="width:100%; min-height: calc(1.5em * 10);" 
                :label="$t('__contactQuestionContent')" v-model="datasets.content">
              </v-textarea>

              <div v-if="imageItemList[0]" class="pb-2">
                <v-img :src="imageItemList[0]" eager draggable="true"></v-img>
              </div>
              <div class="inputJpg" style="width:100%">
                <!-- :placeholder="(innerWidth > 1270) ? '*.jpg/.jpeg/.png' : '*.jpg/.png'" -->
                <v-file-input outlined dense background-color="white" class="file" ref="uploader0" prepend-icon=""
                  :label="$t('__contactQuestionPictures')" hint="image/jpeg, image/png, image/jpg"
                  accept="image/jpeg, image/png, image/jpg" v-model="fileItem0">
                  <template v-slot:append>
                    <div class="pt14 text-center" style="width:90px; background: #4880C8; color: white" @click="openFile(0)">
                      {{$t('__selectFile')}}
                    </div>
                  </template>
                </v-file-input>
              </div>
              <div v-if="imageItemList[1]" class="pb-2">
                <v-img :src="imageItemList[1]" eager draggable="true"></v-img>
              </div>
              <div class="inputJpg" style="width:100%">
                <!-- :placeholder="(innerWidth > 1270) ? '*.jpg/.jpeg/.png' : '*.jpg/.png'" -->
                <v-file-input outlined dense background-color="white" class="file" ref="uploader1" prepend-icon=""
                  :label="$t('__contactQuestionPictures')" hint="image/jpeg, image/png, image/jpg" 
                  accept="image/jpeg, image/png, image/jpg" v-model="fileItem1">
                  <template v-slot:append>
                    <div class="pt14 text-center" style="width:90px; background: #4880C8; color: white" @click="openFile(1)">
                      {{$t('__selectFile')}}
                    </div>
                  </template>
                </v-file-input>
              </div>
            </v-col>
            <v-btn class="styleBtn1 border" outlined style="max-width:50%; color:#FFF; background:#4880C8" @click="newDataset()">
              <h5 class="center">{{$t('__sendQuestions')}}</h5>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- <div style="position: absolute; width: 100%; bottom: 0px">
      <MainFooter ref="MainFooter" />
    </div> -->
    <ShowMsg ref="ShowMsg" :onSave="onShowMsg"/>
  </div>
</template>

<script>
import ShowMsg from '@/dialogs/ShowMsg'
import MainFooter from '@/components/MainFooter.vue'
import i18n from '@/plugins/i18n'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.min.css'

export default {
  name: 'ContactUs',
  components: {ShowMsg,Loading,MainFooter},
  mounted(){
    this.language = i18n.locale
    Promise.all([
      new Promise((resolve)=>{resolve(this.getCommonProblem())}),
      new Promise((resolve)=>{resolve(this.getContactTypeFrontendList())}),
    ]).then(()=>{
      if(this.$route.query.id){
        this.orderNumber = this.$Global.decrypt(this.$route.query.id)
      }
      if(sessionStorage.getItem('myCalToken')){
        this.datasets.memberId = sessionStorage.getItem('myCalId')
        this.datasets.memberName = sessionStorage.getItem('myCalName')
        this.datasets.email = sessionStorage.getItem('myCalEmail')
        this.infoGet = true
      }else{
        this.infoGet = false
      }
    })
  },
  data:() => ({
    language:'',
    orderNumber:'',
    datasets:{
      contactType:'',
      content:'',
    },
    haveToken: false,
    contactTypeList:[],
    innerWidth: 0,
    images:{
      background: require('@/assets/image/background-2000.png'),
    },
    isLoading: false,
    initDone: true,
    infoGet: false,
    commonProblem:'',
    commonProblemList:[],
    currentMenuName:'',
    expandedPanel: -1, // 打開第一個面板
    
    fileItem0: null,
    fileItem1: null,
    imageItemList: ['',''],
    requiredField:['memberName','email','contactType','content'],
  }),
  created(){
    this.calculateWindows()
    this.$root.$on('windowInnerHeight',()=>{
      this.calculateWindows()
    })
    this.$root.$on('language',(value)=>{
      this.language = value
    })
  },
  watch:{
    "language":function(){
      this.changeLanguageSel()
    },
    "fileItem0":function(file){
      if (!this.fileItem0) {
        this.$set(this.imageItemList, 0, '')
      }
      var reader = new FileReader();
      if(this.fileItem0){
        reader.readAsDataURL(this.fileItem0)
        reader.onload = () => {
          if(this.$Global.imageCheck(file, reader.result)){
            this.$set(this.imageItemList, 0, reader.result)
          }else{
            this.$refs.ShowMsg.open(21)
            this.fileItem0 = null
          }
        }
      }
    },
    "fileItem1":function(file){
      if (!this.fileItem1) {
        this.$set(this.imageItemList, 1, '')
      }
      var reader = new FileReader();
      if(this.fileItem1){
        reader.readAsDataURL(this.fileItem1)
        reader.onload = () => {
          if(this.$Global.imageCheck(file, reader.result)){
            this.$set(this.imageItemList, 1, reader.result)
          }else{
            this.$refs.ShowMsg.open(21)
            this.fileItem1 = null
          }
        }
      }
    },
  },
  methods:{
    calculateWindows(){
      if(this.$cookie.get('token') || this.$cookie.get('token') == ''){
        this.haveToken = true
      }
      this.innerWidth = window.innerWidth
    },
    goTop() {
      document.documentElement.scrollTop = 0;
    },
    openFile(index){
      this.$refs['uploader' + index].$el.querySelector('input').click()
    },
    removeFile(){
      this.fileItem = null
      this.$set(this.imageItemList, 0, '')
    },
    getCommonProblem(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/informationDetailByCategory/CommonProblem',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.commonProblem = response.data.data[0]
      }).catch(()=>{
      }).finally(()=>{
        self.changeLanguageSel()
      })
    },
    changeLanguageSel(){
      let tmp = this.commonProblem[this.language + 'Content']
      if(tmp){
        this.commonProblemList = JSON.parse(tmp)
        if(this.initDone && this.innerWidth > 959){
          this.currentMenuName = this.commonProblemList[0].chapterName
          this.expandedPanel = 0
          this.initDone = false
        }
      }
    },
    getContactTypeFrontendList(){
      var self = this
      var request = {
        method: 'GET',
        url: '/common/typevByCategory/contactTypeFrontend',
        params: {},
      }
      this.$http(request).then(function(response) {
        self.contactTypeList = response.data.data
      }).catch(()=>{
        self.$refs.ShowMsg.open(0,'系統錯誤','請聯繫系統管理人員，編號為Mmd-7957')
      }).finally(()=>{
        if(this.orderNumber){
          this.datasets.contactType = '3'
          this.datasets.content = '本次詢問單號 ' + this.orderNumber 
        }
      })
    },
    async newDataset() {
      if(!this.$Global.checkForm(this.datasets,this.requiredField)){
        this.$refs.ShowMsg.open(1)
        return
      }
      
      // 有附加圖片，要先上傳圖片，拿回ImgKey
      if(this.fileItem0){
        this.datasets.note1 = await this.fileUpload(this.fileItem0)
        this.fileItem0 = null
      }
      if(this.fileItem1){
        this.datasets.note2 = await this.fileUpload(this.fileItem1)
        this.fileItem1 = null
      }
      this.isLoading = true
      var allData = this.$Global.copy(this.datasets)
      // allData = this.$Global.jsonConcat(allData,{'id': ''})
      allData = this.$Global.jsonConcat(allData,{'sourceSystem': '2'})
      allData = this.$Global.jsonConcat(allData,{'status': '2'})
      // console.log(allData)
      var self = this
      var request = {
        method: 'POST',
        url: '/common/contactUs',
        data: this.Qs.stringify(allData)
      }
      this.$http(request).then(function(response) {
        self.$refs.ShowMsg.open(6,'','',true,true)
        self.isLoading = false
      }).catch(()=>{
        self.$refs.ShowMsg.open(0,'系統錯誤','請聯繫系統管理人員，編號為Cot-7957')
        self.isLoading = false
      }).finally(()=>{
        self.imageItemList[0] = ''
      })
    },
    async fileUpload(file){
       let formData = new FormData()
      // formData.append("directory", this.$route.query.subjectCode)
      formData.append("file", file)

      var self = this
      var request = {
        method: 'POST',
        url: '/file/common/fileUploadToDir?directory=contactUs',
        headers: {
          'Content-Type':'multipart/form-data'
        },
        data:formData
      }
 
      let res
      await this.$http(request)
      .then(function(response) {
        // 檔案名稱
        // console.log('[fileUpload] response.data.data.realFileName: ' + response.data.data.realFileName)
        res = response.data.data.realFileName
      }).catch(()=>{
        alert('上傳過程失敗，請洽管理員')
      }).finally(()=>{
      })
      return res
    },
    selectMenu(menu){
      if(this.currentMenuName != menu.chapterName)
        this.currentMenuName = menu.chapterName
      else
        this.currentMenuName = ''
    },
    onShowMsg(){
      this.datasets.contactType = ''
      this.datasets.content = ''
      this.datasets.note1 = ''
      this.datasets.note2 = ''
    },
  }
}
</script>